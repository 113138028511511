infographic-panel,
[infographic-panel] {
  .panel-body > div:last-child {
    margin-bottom: 0;
  }

  .infographic-container {
    display: flex;
  }

  .infographic-text {
    flex-grow: 1;
    padding-right: 30px;
  }

  .upload-hint {
    margin-bottom: 0;
  }

  .infographic-preview {
    flex: none;
    min-width: 100px;
    text-align: right;
    width: 30%;

    & img {
      max-width: 200px;
      width: 100%;
    }
  }

  .default-img {
    background: #e0e0e0;

    & img {
      opacity: .1;
      padding: 10% 28%;
    }
  }

  input[type='file'] {
    bottom: 0;
    // The file input must always have the same cursor as the label
    cursor: inherit !important;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

  }

  input[type='file']::file-selector-button {
   // The file input button must always have the same cursor as the label
    cursor: inherit !important;
  }

  .label-btn:focus-within {
    background-color: #286090;
    border-color: #122b40;
    color: #fff;
    outline: 5px auto Highlight;  // CSS system color, supported widely
    outline-color: -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  .label-btn {
    max-width: 210px;
    position: relative;
    width: 100%;
  }

  .btn-remove {
    padding-right: 0;
  }

  .article-infographic-example {
    margin-left: auto;
    margin-right: auto;
    width: 75%;

    img {
      max-width: 100%;
    }
  }
}
