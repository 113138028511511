.non-clickable-link {
  pointer-events: none;

  i.grey {
    color: $kudos-dark-grey;
  }
}

.clickable {
  cursor: pointer;
}

.table-aligned-link {
  margin-left: $table-cell-padding;
}

.white-link,
a.white-link:hover,
a.white-link:focus {
  color: #fff;
}

// We need to beat Bootstrap's link styles by being slightly more specific,
// hence the body parent selector:
body :where(a:not(.btn):not(.social-link)) {
  color: inherit;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}
