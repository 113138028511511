// Style 1st-level ul/li's with a gutter, but don't cascade
@mixin list-with-left-image-gutter($image-width, $image-height: $image-width, $image-padding: 20px) {
  list-style-type: none;
  padding-left: 0;

  > li {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: $image-width, $image-width;
    min-height: $image-height;
    padding-left: $image-width + $image-padding;
    position: relative; // for abs positioning of avatar

    .list-item-img {
      left: 0;
      max-height: $image-height;
      max-width: $image-width;
      position: absolute;
      top: 0;
    }
  }
}

.list-spacious {
  li {
    margin: 10px 0;
  }
}

.left-avatar-gutter {
  @include list-with-left-image-gutter($avatar-width);
}

.left-cover-gutter {
  @include list-with-left-image-gutter(70px, 105px, 15px);
}

.left-icon-gutter {
  @include list-with-left-image-gutter($avatar-width / 2, $avatar-width / 2, 10px);
}

.left-resource-icon-gutter {
  @include list-with-left-image-gutter(20px, 20px, 10px);
}

.list-no-gutter {
  padding-left: 20px;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
