.shares-by-channel {
  .column-header-container {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-around;

    .column-header {
      font-weight: bold;
      text-align: center;
    }

    .full-title {
      @media screen and (max-width: $screen-xs-min) {
        display: none;
      }
    }

    .short-title {
      display: none;
      @media screen and (max-width: $screen-xs-min) {
        display: inline-block;
      }
    }
  }

  .channel-divider {
    p {
      text-align: center;
      margin: 0;
    }

    #spdf-title {
      text-align: center;
      background-color: $kudos-light-grey;
      padding: 5px;

      i {
        margin-right: 3px;
      }
    }

    #spdf-upsell-message {
      padding: 10px;
    }
  }

  .channel-container {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    .no-of-shares-container {
      flex-grow: 2;
      flex-basis: 100px;
    }

    .average-clicks-container {
      flex-grow: 1;
      flex-basis: 100px;
    }

    .channel-group {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid $kudos-light-grey;
      height: 80px;

      @media screen and (max-width: $screen-xs-min) {
        height: 100px;
      }

      .channel-label {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex: 0 0 60px;
        max-width: 60px;

        @media screen and (max-width: $screen-xs-min) {
          word-wrap: break-word;
        }
      }

      .channel-stats {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media screen and (min-width: $screen-xs-min) {
          flex-direction: row;
          flex: 0 0 110px;
          max-width: 110px;
        }

        .channel-count {
          margin: 2px 0 0 5px;
        }

        .share-icon {
          background-color: #bbb;
          border-radius: 50%;
          color: #fff;
          height: 34px;
          line-height: 34px;
          text-align: center;
          width: 34px;
        }

        .link-background-color {
          background-color: $kudos-orange;
        }

        .twitter-background-color {
          background-color: $brand-twitter-color;
        }

        .linkedin-background-color {
          background-color: $brand-linkedin-color;
        }

        .facebook-background-color {
          background-color: $brand-facebook-color;
        }

        .wechat-background-color {
          background-color: $brand-wechat-color;
        }

        .weibo-background-color {
          background-color: $brand-weibo-color;
        }

        .mastodon-background-color {
          background-color: $brand-mastodon-color;
        }

        .bluesky-background-color {
          background-color: $brand-bluesky-color;
        }

        .whatsapp-background-color {
          background-color: $brand-whatsapp-color;
        }

        .researchgate-background-color {
          background-color: $brand-researchgate-color;
        }

        .academia-background-color {
          background-color: $brand-academia-color;
        }

        .mendeley-background-color {
          background-color: $brand-mendeley-color;
        }

        .other-background-color {
          background-color: $channels-other-color;
        }

        .channel-inactive-background-color {
          background-color: $channels-inactive-color;
        }

        p {
          font-size: 10px;
        }
      }
    }
  }
}

.no-margin {
  margin: 0;
}

.spdf-background-color {
  background-color: #f4f4f4;
}
