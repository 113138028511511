.background-image-container {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &.background-present {
    .container {
      margin-top: 50px;
    }
  }

  .container {
    background-color: #fff;
    padding-top: 10px;
    margin-bottom: 50px;
  }

}

.fullpage {
  @include fullpage;
  &.row {
    width: auto;
  }
}

.fullpage-background {
  @include fullpage-background("/images/homepage/jumbotron_kudos_mountain.jpg");
}

.extra-light-grey-background {
  background-color: $kudos-extra-light-grey;
}

// TODO: replace .extra-light-grey-background bg color with this, then remove
//       the .hub-extra-light-grey-background class.
.hub-extra-light-grey-background {
  background-color: $kudos-extra-light-grey2;
}

.light-blue-background {
  background-color: $kudos-light-blue;
}
