.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.always-on-top{
  position: fixed;
  z-index: 999;
}

.vertically-centered-inline {
  display: inline-block;
  vertical-align: middle;

  // prevents the first child from being unnecessarily indented
  &:not(:first-child) {
    margin-left: 1em;
  }

  // remove margin from the first/last children to help with centring
  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}

// Pulled from application.scss
.inline-block {
  display: inline-block;
}
