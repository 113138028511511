// Hide some things to show only when printing.
.publication-page {
  #plaintext-url {
    display: none;
  }
}

// Styles for the publication read page when printing.
@media print {
  .publication-page {
    #plaintext-url {
      display: initial;
      p {
        font-size: 18px;
        margin-top: 25px;
      }
    }

    html,
    body,
    main {
      position: relative;
      height: 100%;
    }

    .light-blue-background {
      background-color: $kudos-light-blue !important;
      border: 1px $kudos-light-blue solid;
      position: relative;
      top: -1px;
    }

    // A class that can be added to anchor tags to prevent them having their
    // href appended when printed:
    a[href].hidden-print-href:after {
      content: "";
    }


    // Hide all elements that are not important
    .profiler-results,
    #epd {
      display: none;
    }

    #read-publication-row {
      margin: 0 0 2em 0 !important;
    }

    .row-section {
      padding: 0.5em 0;
    }

    #main-content-container {
      padding: 2em 0 0.5em 0;
    }

    .gutter-right {
      padding-right: 15px;
    }

    .container {
      margin-right: auto;
      margin-left: auto;
      padding-left: 1.5cm;
      padding-right: 1.5cm;
    }

    #left-column,
    #right-column {
      font-size: 14px;
    }

    #left-column {
      width: 80%;
    }

    #right-column {
      width: 20%;
    }

    h1,
    .h1 {
      color: #000000 !important;
      font-size: 1.9em;
    }

    h3,
    .h3 {
      margin-top: 25px;
      color: #4784c3 !important;
    }

    h4,
    .h4 {
      color: $kudos-orange !important;
    }

    .author-perspective {
      padding: 5px 0 0 0;

      .tbl {
        page-break-inside: avoid;
      }
    }

    #read-publication-link {
      &:after {
        content: '';
      }

      color: $state-info-text !important;
      font-size: 1.5em;
      padding: 0;
      text-decoration: underline;
      font-weight: 700;
    }

    #partnership-text,
    #contributor-attribution-notice,
    #print-media-pdf-footer,
    #print-media-only-banner {
      font-size: 0.8em;
    }

    #contributor-attribution-notice,
    #print-media-pdf-footer {
      color: $kudos-grey !important;
      margin: 15px 0 5px 0;
    }

    #print-media-only-banner {
      background-color: $kudos-blue !important;
      border: 1px $kudos-blue solid;
      position: relative;
      top: -1px;
      padding: 5px 0 0 0;

      p,
      strong {
        color: #fff !important;
      }
    }

    #partnership-image {
      max-width: 112px !important;
    }

    #cover-image {
      max-height: 120px;
    }

    #print-media-pdf-kudos-logo {
      margin-top: 10px;
      width: 140px;
    }
  }
}
