$row-section-spacing: 40px;

.fixed-background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body {
  &.page-signin,
  &.registration {
    background-color: #a4b9ca;
    background-image: url("/images/registration_page_background.jpg");
  }

  &.fresh-space,
  &.registration {
    // Override for Bootstrap navbar-fixed-top on registration to prevent
    // content overlap
    .navbar.navbar-fixed-top {
      position: sticky;
      top: 0px;
    }

    h2 {
      margin-bottom: ($line-height-computed / 2);
    }

    .row-section {
      transition: margin 300ms;

      &.primary,
      &.secondary {
        margin-top: 0;
        margin-bottom: 0;
      }

      @media (min-width: $screen-sm-min) {
        &.primary {
          margin-top: $row-section-spacing;
        }

        &.secondary {
          margin-bottom: $row-section-spacing;
        }
      }
    }
  }

  &.registration {
    .photocredit-wrapper {
      min-height: calc(100vh - 52px);
    }
  }

  .welcome-image {
    max-height: 45px;
  }

  .welcome-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px;

    @media (min-width: $screen-sm-min) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .registration_job_role {
    h1 {
      font-size: 18px;
      font-weight: 500;
      color: $state-info-text;
    }

    label {
      font-size: 1.25em;
      font-weight: 700;
      color: $kudos-orange;
    }

    .save-button {
      margin-left: 10px;
    }
  }
}
