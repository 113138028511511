.page-hub-add {
  .fullpage {
    padding-top: 1rem;
  }

  .title-row {
    margin-bottom: 1em;
  }

  kudos-popover-box a {
    font-size: 0.75rem;
  }

  @media (min-width: $screen-md-min) {
    .fullpage {
      padding-top: 5rem;
    }

    .title-row {
      margin-bottom: 3em;
    }
  }
}
