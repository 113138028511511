// Need to refactor these bespoke unsemantic styles out
.btn-kudos-orange { @include button-variant(#fff, $kudos-orange, $kudos-orange) }
.btn-kudos-light-orange { @include button-variant(#fff, $kudos-light-orange, $kudos-light-orange) }
.btn-kudos-white-light-orange{ @include button-variant($kudos-light-orange, #fff , #fff) }
.btn-kudos-blue { @include button-variant(#fff, $kudos-blue, $kudos-blue) }
.btn-kudos-white-blue{ @include button-variant($kudos-blue, #fff , #fff) }
.btn-kudos-blue-white{ @include button-variant(#fff, $kudos-blue , #fff) }

.btn-primary-plus {
  @include button-variant(#fff, $kudos-orange, $kudos-orange);
}

.btn-hang-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: $kudos-purple;
  border-color: $kudos-purple;
  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active,
  &:hover:active {
      background-color: $kudos-light-purple;
      border-color: $kudos-light-purple;
    }
}

.btn-muted {
  @include button-variant(#fff, $kudos-grey, $kudos-grey)
}

.btn-lg2 {
  font-size: 18px;
  line-height: 4/3;
  padding: 12px 16px;
  min-width: 165px;
  border-radius: 8px;
}

.btn-animated-progress {
  transition: color 300ms, background 300ms, border 300ms;
  position: relative;
  outline: none;
  overflow: hidden;

  .icon-container {
    position: absolute;
    top: 0;
    left: -30px;
    height: 100%;
    width: 30px;

    .icon {
      position: relative;
      transition: transform 300ms, opacity 300ms;
      opacity: 0;
      transform: translateX(-50px);
    }
  }

  .text-container {
    display: inline-block;
    position: relative;
    transition: margin 300ms;
    margin: 0;
  }

  &.waiting,
  &.success,
  &.error {
    .text-container {
      margin-left: 15px;
    }

    .icon-container {
      .icon {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &.init {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  }

  &.waiting {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
  }

  &.success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
  }

  &.error {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
  }
}

// A copy of Bootstrap's button-variant mixin but with all focus/hover/active
// styles removed so the button doesn't change state.
@mixin button-variant-no-hover-focus-active($color, $background, $border) {
  &.no-hover-focus-active {
    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active {
      color: $color;
      background-color: $background;
      border-color: $border;
    }
    &:active,
    &.active {
      @include box-shadow(none);
    }
  }
}

// Copied from bootstrap-sass-official/assets/stylesheets/bootstrap/_buttons.scss
// because we want to add the above mixin to all the button classes.
.btn-default {
  @include button-variant-no-hover-focus-active($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
  @include button-variant-no-hover-focus-active($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
.btn-success {
  @include button-variant-no-hover-focus-active($btn-success-color, $btn-success-bg, $btn-success-border);
}
.btn-info {
  @include button-variant-no-hover-focus-active($btn-info-color, $btn-info-bg, $btn-info-border);
}
.btn-warning {
  @include button-variant-no-hover-focus-active($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
.btn-danger {
  @include button-variant-no-hover-focus-active($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.orcid-button {
  white-space: normal;

  img {
    margin-right: 5px;
  }
}
