// General card flip styles
.card-flip {
  perspective: 1000px;
  position: relative;

  .front,
  .back {
    width: 100%;
    cursor: pointer;

    transition: transform 300ms ease-out;
    transform-style: preserve-3d;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
  }

  &.flipped {
    .front {
      transform: rotateY(-180deg);
    }

    .back {
      transform: rotateY(0deg);
    }
  }
}

$flip-icon-size: 27px;
$flip-icon-indent: 5px;

// Publication card flip styles
.card-flip-publication {
  height: 260px;

  .front,
  .back {
    // transition the rotating transform, and the background position (for the shaded edge gradient)
    transition: transform 300ms ease-out, background-position 200ms ease-in-out;
  }

  .panel {
    height: 245px;
    margin-bottom: 15px;
    overflow: hidden;

    // Background linear gradient provides a flip feel to the card, this is positioned
    // just off the the bottom left of the card
    background: linear-gradient(135deg, #fff 93%, rgba(219, 221, 222, 0.9) 100%);
    background-size: 200%;
    background-position: 88% 88%;

    h1 {
      margin-top: 0;
    }

    .panel-body {
      background: transparent;
    }

    // Make sure that flip icon on the back of the card is not visible
    &.front {
      .flip-icon {
        opacity: 100;
      }
    }

    &.back {
      .flip-icon {
        opacity: 0;
      }
    }

    .flip-icon {
      transition: transform 200ms ease-out, opacity 100ms;

      transform: rotate(0deg);

      position: absolute;
      bottom: $flip-icon-indent;
      right: $flip-icon-indent;
      border-radius: 15px;
      padding: 5px;
      background-color: $kudos-extra-light-grey;
      color: $kudos-blue;
      width: $flip-icon-size;
      height: $flip-icon-size;
      text-align: center;
    }
  }

  // Make sure that flip icon on the back of the card is not visible
  &.flipped {
    .front {
      .flip-icon {
        opacity: 0;
      }
    }

    .back {
      .flip-icon {
        opacity: 100;
      }
    }
  }

  // On hover, pull the linear background gradient into the bottom right corner for
  // card flip feel and spin the flip icon 180 degrees.
  &:hover {
    .panel {
      background-position: 100% 100%;

      .flip-icon {
        transform: rotate(180deg);
      }
    }
  }
}

$card-panel-body-padding: 15px;

.card-flip-hub {
  height: auto;
  .panel {
    height: auto;
    .panel-body {
      padding: $card-panel-body-padding;
    }
    .space-for-flip-icon {
      // Get distance from edge of card that includes all of the flip-icon,
      // then minus the padding by which this element will be pushed in.
      $flip-icon-space-in-card-corner: $flip-icon-size + $flip-icon-indent - $card-panel-body-padding;
      width: $flip-icon-space-in-card-corner;
      height: $flip-icon-space-in-card-corner;
    }
    @media screen and (max-width: $screen-sm-min - 1) {
      // Cancel the equal-height columns setting from javascript.
      min-height: 0 !important;
    }
  }
  &:not(.flipped) .panel {
    &.front {
      position: relative;
    }
    &.back {
    }
  }
  &.flipped .panel {
    &.front {
    }
    &.back {
      position: relative;
    }
  }
}
