.checkbox-container {
  margin-top: 1em;

  input[type=checkbox] {
    cursor: pointer;
    display: inline-block;
    width: 34px;
    padding-left: 20px;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
  }

  input[type=checkbox][disabled] {
    cursor: not-allowed;
  }
}
