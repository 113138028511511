// Angular x-editable styling
.editable-wrap {
  display: block;
  white-space: nowrap;
  margin: 0;
  .editable-controls,.editable-wrap .editable-error, .control-group.form-group{
    width:100%;
    margin-bottom: 0;
    >input{
      width: 100%;
    }
    >textarea{
      width: 100%;
      resize: vertical;
      height: 110px;
    }
    .editable-buttons {
      .btn {
        margin-left: 3px;
      }
    }
  }
}

.editable-wrap .editable-controls,
.editable-wrap .editable-error,
.editable-wrap .editable-controls>input,
.editable-wrap .editable-controls>select,
.editable-wrap .editable-controls>textarea {
    margin-bottom: 0;
}

.editable-wrap .editable-input {
  display: inline-block;
}

.editable-buttons {
    display: inline-block;
    vertical-align: top;
}

.editable-buttons button {
    margin-left: 5px;
}

.editable-input.editable-has-buttons {
    width: auto;
}

.editable-bstime .editable-input input[type=text] {
    width: 46px;
}

.editable-bstime .well-small {
    margin-bottom: 0;
    padding: 10px;
}

.editable-range output {
    display: inline-block;
    min-width: 30px;
    vertical-align: top;
    text-align: center;
}

.editable-color input[type=color] {
    width: 50px;
}

.editable-checkbox label span,
.editable-checklist label span,
.editable-radiolist label span {
    margin-left: 7px;
    margin-right: 10px;
}

.editable-hide {
  display: none!important;
}

.editable-click,
a.editable-click,
.editable-click:hover,
a.editable-click:hover {
  text-decoration: none;
}

.editable-empty,
.editable-empty:hover,
.editable-empty:focus,
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
  text-decoration: none;
}

.edit-disabled,
.edit-disabled:hover {
  color: black;
  text-decoration: none;
}


// Lays out the save/cancel buttons to the right of the form field (inline)
.buttons-right {
  .editable-controls.form-group {
    display: table;
    width: 100%;
  }
  .editable-has-buttons {
    width: 100%;
    display: table-cell;
  }
  .editable-buttons {
    float: none;
    display: table-cell;
    width: 53px;
  }
}

// Lays out the save/cancel buttons below the form field (block)
.buttons-under {
  .editable-buttons {
    float: right;
    margin-top:0.2em;
    display: block;
  }
}

.with-edit-icon {
  position: relative;

  .edit-icon {
    font-size: $font-size-base;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.edit-button-container {
  position: relative;
  .edit-button {
    &.edit-button-top-right {
      position: absolute;
      top: 17px;
      right: 14px;
    }
  }
}

.edit-button {
  font-size: $font-size-base;
}

.editable {
  overflow: auto;
}
