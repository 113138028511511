.for-researchers {
  background-image: url("/images/about/for_researchers_background.png");
}

.for-research-groups {
  background-image: none;
}

.for-publishers {
  background-image: url("/images/about/for_publishers_background.png");
}

.for-institutions {
  background-image: url("/images/about/for_institutions_background.jpg");
}

// .about-us {
//   background-image: url("/images/about/about_us_background.png");
// }

.for-researchers, .for-publishers, .for-institutions, .about-us {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% auto;

  .subpage-content {
    padding-bottom: 75px;
  }

  h1 {
    color: black;
  }

  i, #more {
    color: $kudos-blue;
    font-size: 25pt;
    font-weight: 700;
  }

  #more {
    font-size: 20pt;
  }

  .panel.box-bordered {
    border: 3px solid $kudos-blue;
    background-color: rgba(255, 255, 255, 0.9);
    height: 180px;
    position: relative;
  }

  .blue-box {
    margin-bottom: $line-height-computed;
    background-color: $kudos-blue;
    color: white;
    font-size: $font-size-base;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #ed5c25;
      }
    }
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-bottom: 30px solid $kudos-blue;
    padding-bottom: 0px;
    margin: auto;
  }
}

.for-research-groups {
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
  }

  .youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 8px solid $kudos-extra-light-grey;
    border-radius: 5px;
  }

  .btn-container {
    text-align: center;
    @media screen and (min-width: $screen-sm-min) {
      text-align: left;
    }
  }

  .large-icon {
    font-size: 36px;
  }

  .panel-body {
    .numbers {
      color: $kudos-blue;
		}
	}

  img {
    margin-top: 15px;
  }

  .check-list {
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 60px;
      position: relative;
      margin-bottom: 30px;
		}

    li::before {
      background-image: url('/images/check.png');
      background-position: center;
      background-size: cover;
      content: '';
      height: 36px;
      top: 50%;
      left: -10px;
      position: absolute;
      transform: translateY(-42%);
      width: 34px;
    }
  }

  .register-interest-fields {
    .form-control {
      height: 45px;
      font-size: 16px;
      margin: 10px 0 20px 0;
    }

    .btn {
      border-width: 3px;
    }
  }

  .box {
    cursor: pointer;
    height: 60px;
    position: relative;

    border: 2px solid white;
    border-radius: 12px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05);

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 2fr 1fr;

    @media screen and (min-width: $screen-sm-min) {
      height: 180px;
    }

    svg {
      grid-column: 1;
      grid-row: 1 / -1;

      height: 35px;
      margin: auto;

      @media screen and (min-width: $screen-sm-min) {
        grid-column: 1 / -1;
        grid-row: 1;

        height: 100px;
        padding-top: 25px;
      }
    }

    p {
      grid-column: 2 / -1;
      grid-row: 1 / -1;
      margin-left: 20px;

      @media screen and (min-width: $screen-sm-min) {
        grid-column: 1 / -1;
        grid-row: 2;

        margin: auto;
      }
    }
  }

  .blue-hover {
    &:hover {
      background-color: #18669D;
    }
  }

  .white-hover {
    &:hover {
      background-color: $kudos-extra-light-grey;
    }
  }

  .divider-bottom {
    border-bottom: 2px solid $kudos-extra-light-grey;
  }

  h3 {
    color: $kudos-grey;
    font-size: 36px;
  }

  .footer-image {
    background-image: url('/images/KFRG_bottom.jpg');
    background-size: cover;
    height: 200px;

    @media screen and (min-width: $screen-sm-min) {
      height: 300px;
    }
  }
}

.about-us {
  .panel.box-bordered {
    height: 480px
  }

  .team-advisors {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
