
$sidebar-width: 250px !default;
$sidebar-transition-duration: 150ms !default;
$sidebar-link-color: #fff !default;

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: $sidebar-width;
  background: rgba($kudos-dark-grey, 0.987);

  // ensure it floats over everything
  z-index: 1000;

  padding-top: ($navbar-height + 25);

  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transition: transform $sidebar-transition-duration ease-in-out;
  -webkit-transition: -webkit-transform $sidebar-transition-duration ease-in-out;

  font-size: 1.166666667em;

  a {
    color: $sidebar-link-color;

    &:hover {
      //white needs more darkening for effect...
      color: darken($sidebar-link-color, $link-hover-darken*2);
      text-decoration: none;
    }
  }
}

.sidebar-list {
  padding-top: 1.5em;
}

.sidebar-vertical-list {
  padding-left: 0;
  list-style: none;


  &>li {
    padding-bottom: 0.7em;
    padding-left: 0;
    list-style: none;
  }
}

.border-left-light {
  border-left: 1px solid $kudos-light-grey;
}

.border-right-light {
  border-right: 1px solid $kudos-light-grey;
}

.sidebar-shown {
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
}
