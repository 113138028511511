$altmetric-kpi-size: 95px;

.altmetric-kpi {
  altmetric-badge {
    margin: auto;
    .altmetric-embed {
      // Enforce altmetric embed size
      img {
        width: $altmetric-kpi-size !important;
        height: $altmetric-kpi-size !important;
        background-size: $altmetric-kpi-size !important;
      }

      // Hide altmetric embed popover
     #_altmetric_popover_el {
       display: none !important;
     }

     // Hide altmetric embed legend
     .altmetric-normal-legend {
       float: none !important;
       margin: 0 !important;

       .altmetric-see-more-details {
         display: none !important;
       }
     }

     // Style override for non-existent DOI on altmetric
     > a {
       width: 100px !important;
       height: 100px !important;
       background-size: 100px !important;
     }
    }
  }

  // Blurred disabled altmetric kpi placeholder styles
  &.altmetric-kpi-disabled {
    altmetric-badge {
      display: block;
      background: none;
      background-image: url('/images/kpi-obscured-altmetric.png');
      color: transparent;
      background-size: cover;
      width: 120px;
      height: 120px;
      margin-top: -10px;

      .altmetric-embed {
        display: none;
      }
    }
  }
}

.altmetric-badge-centered {
  .altmetric-normal-legend {
    float: none;
    margin: auto;
    display: block;
    text-align: center;
  }
}
