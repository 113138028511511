.nav {
  &.nav-tabs {
    margin-top: 10px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }

    li {
      &.tab-note {
        p {
          margin: 0;
          padding-top: 8px;
          padding-bottom: 0;

          @media (min-width: $screen-sm-min) {
            padding-bottom: 4px;
            padding-top: 4px;
          }
        }
      }
    }
  }
}
