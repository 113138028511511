.kudos-loader {
  -webkit-transition: -webkit-transform 300ms ease-in, opacity 250ms ease-in, height 300ms;
  transition: transform 300ms ease-in, opacity 250ms ease-in, height 300ms;
  -ms-transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  opacity: 0;
  height: 0;
  overflow: hidden;

  &.fade-in {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
    height: 100px;
  }

  .progress-bar {
    background-color: $kudos-grey;
  }
}
