.ux3 {
  .sign-in-page {
    h3 {
      color: black;
      margin-bottom: 30px;
    }

    .padding-right {
      padding-right: 30px;
    }

    .register-button {
      width: 65%;
    }

    .primary-sign-in-row {
      margin-top: 30px;
      margin-bottom: 80px;
      padding-bottom: 0.5em;

      @media screen and (max-width: $screen-sm-min) {
        margin-top: 15px;
      }
    }

    .trial-access-notice-container {
      margin-top: 30px;
      font-size: 0.875rem;

      .trial-access-notice, .trial-access-notice-lite {
        margin: 0 30px;
      }

      .trial-access-notice-lite {
        @media screen and (max-width: $screen-sm-min) {
            margin: 0;
          }
        }
    }

    .panel {
      margin-bottom: 0;
    }

    .social-facebook-link,
    .social-twitter-link,
    .social-linkedin-link {
      color: $kudos-light-grey;
    }

    .register-text {
      text-align: center;
      font-size: 0.875rem;

      div {
        margin: 10px;
      }

      .testimonial-image-container {
        width: 50%;
        margin: 10px auto;

        img {
          max-width: 100%;
        }
      }

      .testimonial:before {
        color: $kudos-fuschia-pink;
        content: open-quote;
      }

      .testimonial:after {
        color: $kudos-fuschia-pink;
        content: close-quote;
      }

      .testimonial-attribution {
        line-height: 1.5em;

        p {
          margin: 0;
        }

        p:first-child {
          text-transform: uppercase;
          font-weight: bold;
          color: $kudos-fuschia-pink;
        }
      }
    }
  }
}
