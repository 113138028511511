//
// Bordered boxes of various ilks
//

.box {
  position: relative;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.box-bordered {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}

.box-default {
  @extend .box-bordered;
  background-color: #fff;
}

.box-card {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.box-primary {
  @extend .box-bordered;
  background-color: $kudos-orange;
  color: #fff;

  h1, .h1, h2, .h2, h3, .h3 {
    color: #fff;
  }
}

@mixin box-with-left-image-gutter($image-width, $image-height: $image-width, $image-padding: 20px) {
  @extend .box;

  text-align: left;
  font-size: 1.2rem;

  // Style 1st-level ul/li's with a gutter, but don't cascade
  >ul {
    @include list-with-left-image-gutter($image-width, $image-height: $image-height);

    >li {
      &.group-of-authors {
        background-image: url('/images/sample-box-image.gif');
      }

      .avatar {
        left: 0;
        max-height: $image-height;
        max-width: $image-width;
        position: absolute;
        top: 0;
      }
    }
  }
}

.box-with-avatar {
  @extend .box-default;
  @include box-with-left-image-gutter($avatar-width);
}

.card-box-with-cover {
  @extend .box-card;
  @include box-with-left-image-gutter(70px, 104px);
}

.box-with-cover {
  @extend .box-default;
  @include box-with-left-image-gutter(60px, 104px);
}

.box-with-icon {
  @extend .box-default;
  @include box-with-left-image-gutter(30px);
}

.box-with-icon--cover {
  @extend .box-default;
  @include box-with-left-image-gutter(30px, 42px);
}

// Pulled out of general styles in application.scss
.author-box-with-avatar {
  min-height: 180px;
}

.related-publication-box-with-avatar {
  min-height: 160px;

  .lay-summary-content {
    font-size: 11px;
  }
}
