.ux2,
.ux3,
.ux4 {
  .banner {
    width: 100%;

    p {
      margin: 0;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.banner-sm {
  padding: 1em 0.5em;
}

.banner-lg {
  padding: 1.5em 1em;
}

.banner-primary {
  background-color: $kudos-orange;
  color: #fff;
}

.banner-success {
  background-color: $kudos-green;
  color: #fff;
}

.pro-banner {
  width: 100%;
  display: none;

  @media screen and (min-width: $screen-xs-min) {
    display: block;
  }
}

.pro-banner-mobile {
  width: 100%;
  display: block;

  @media screen and (min-width: $screen-xs-min) {
    display: none;
  }
}
