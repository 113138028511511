/**
 * AngularJS adds CSS classes automatically given a base class, so so adding
 * `class=animation` to an ng-repeat element will use this set of transitions.
 **/
.animation {

  // We'll fade in quickly to get the message on-screen ASAP
  &.ng-enter {
    transition: all linear 100ms;
    opacity: 0;
  }

  &.ng-enter-active {
    opacity: 1;
  }

  // fade out slower (with still within Google's 350ms guidelines)
  &.ng-leave {
    transition: all linear 300ms;
    opacity: 1;
  }

  &.ng-leave-active {
    opacity: 0;
  }
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-duration: 300ms;
  animation-name: fade-in-animation;

  &.slow {
    animation-duration: 600ms;
  }
}

@keyframes throb-in-animation {
  0% {
    opacity: 0;

    -ms-transform: scale(0, 0); /* IE 9 */
    -webkit-transform: scale(0, 0); /* Safari */
    transform: scale(0, 0);
  }

  20% {
    -ms-transform: scale(0.6, 0.6); /* IE 9 */
    -webkit-transform: scale(0.6, 0.6); /* Safari */
    transform: scale(0.6, 0.6);
  }

  50% {
    opacity: 1;
  }

  60% {
    -ms-transform: scale(1.25, 1.25); /* IE 9 */
    -webkit-transform: scale(1.25, 1.25); /* Safari */
    transform: scale(1.25, 1.25);
  }

  100% {
    opacity: 1;

    -ms-transform: scale(1, 1); /* IE 9 */
    -webkit-transform: scale(1, 1); /* Safari */
    transform: scale(1, 1);
  }
}

.throb-in {
  animation-duration: 400ms;
  animation-name: throb-in-animation;

  &.slow {
    animation-duration: 600ms;
  }
}

@keyframes throb-animation {
  0% {
    -ms-transform: scale(0.98, 0.98); /* IE 9 */
    -webkit-transform: scale(0.98, 0.98); /* Safari */
    transform: scale(0.98, 0.98);
  }

  50% {
    -ms-transform: scale(1.02, 1.02); /* IE 9 */
    -webkit-transform: scale(1.02, 1.02); /* Safari */
    transform: scale(1.02, 1.02);
  }

  100% {
    -ms-transform: scale(0.98, 0.98); /* IE 9 */
    -webkit-transform: scale(0.98, 0.98); /* Safari */
    transform: scale(0.98, 0.98);
  }
}

.throb {
  animation: throb-animation 3s ease-in-out forwards infinite;
}

@keyframes sway-animation {
  0% {
    -ms-transform: rotate(3deg); /* IE 9 */
    -webkit-transform: rotate(3deg); /* Safari */
    transform: rotate(3deg);
  }

  50% {
    -ms-transform: rotate(-10deg); /* IE 9 */
    -webkit-transform: rotate(-10deg); /* Safari */
    transform: rotate(-10deg);
  }

  100% {
    -ms-transform: rotate(3deg); /* IE 9 */
    -webkit-transform: rotate(3deg); /* Safari */
    transform: rotate(3deg);
  }
}

.sway {
  animation: sway-animation 3s ease-in-out forwards infinite;
}
