@font-face {
  font-family: "DM Sans";
  src: url("/fonts/dm-sans-regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("/fonts/dm-sans-bold.woff2");
  font-weight: bold;
  font-style: normal;
}

body.s-pdf {
  // We can't use Messina Sans for S-PDF, as we're not licensed to use it in
  // documents.
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
  letter-spacing: normal;
}

@media print {
  // @page specifies the print page settings
  // Margin overrides default page print margins
  // in Chrome. This ensures that header/footer
  // are printed full width.
  @page {
    size: auto;
    margin: 0.7cm 0 0.8cm 0;
  }

  @page :first {
    margin: 0 0 0.8cm 0;
  }

  * {
    -webkit-transition: none !important;
    transition: none !important;
  }

  // Hides header components on the printout.
  kudos-navbar,
  mag-ui-nav-bar,
  mag-ui-guest-user-job-role-collection,
  kudos-confirmation-banner,
  footer,
  .banner,
  .navbar-fixed-hang,
  #_hj_feedback_container,
  // hide Zendesk widget
  iframe#launcher {
    display: none;
  }

  // Bootstrap grid classes are not present in BS3
  // so we we include them here but only the col-sm-* ones.
  @include make-grid(sm);

  .with-static-header {
    padding-top: 0;
  }

  .hidden-sm,
  .hidden-xs {
    &:not(.hidden-print) {
      display: block !important;
    }
  }
}
