$kpi-widget-value-size: 95px !default;

.kpi-widget {
  text-align: center;

  .heading {
    font-size: 13px;
    color: $kudos-dark-grey;
    margin-top: 0;
    margin-bottom: 15px;
    height: 2em;
  }

  .value {
    cursor: default;
    text-decoration: none;
    background-color: $kudos-blue;
    padding: 23px 5px 16px 5px;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    display: inline-block;
    width: $kpi-widget-value-size;
    height: $kpi-widget-value-size;
    line-height: 2.5;
  }

  &.clickable {
    .value {
      cursor: pointer;
      text-decoration: default;
      &:hover {
        text-decoration: none;
        background-color: darken($kudos-blue, 10%);
      }
    }
  }

  .footer {
    padding-top: 15px;
    padding-bottom: 25px;

    @media (min-width: $screen-md-min) {
      padding-bottom: 0;
    }
  }
}

.kpi-disabled {
  .value {
    cursor: default;
    background-color: transparent;
    background-image: url('/images/kpi-obscured-blue.png');
    color: rgba(0, 0, 0, 0);
    background-size: cover;
    width: 120px;
    height: 120px;
    margin-top: -10px;
    &:hover {
      background-color: transparent;
    }
  }
}

.kpi-widget-green {
  .value {
    background-color: $kudos-green;
  }

  &.clickable {
    .value {
      &:hover {
        background-color: darken($kudos-green, 10%);
      }
    }
  }

  &.kpi-disabled {
    .value {
      background-color: transparent;
      background-image: url('/images/kpi-obscured-green.png');
    }
  }
}
