.logo {
  &.logo-icon {
    max-height: 60px;
  }
}

.alpsp-border {
  border: 1px #bdbdbd solid;
}

/** Temporary article cover styling **/
.cover {
  width: 50%;
}
