/**
 * Variables
 *
 * Bootstrap and other global variables. This file is often referred to as
 * the "theme" styling.
 **/
@import "_variables";

/**
 * 3rd party libraries
 *
 * These might use variables set above, so we'll load them last
 **/
@import "../../../node_modules/flag-icon-css/sass/_flag-icon.scss";

@import url(angular-toastr.b5654a00.css);
@import url(ui-grid.6bab72b5.css);
@import url(nv.d3.c137d227.css);

//these assets come from npm, not from the bootstrap-sass gem
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "_flaticon";

/**
 * Mixins
 *
 * Helpful reusable mixins to build module components.
 * Perhaps this code should live in "Modules" too...
 **/
@import "_mixins";

/**
 * Globals
 *
 * Also called "Base", these are the global styles. Generally they should be element
 * rather than classes and ID selector
 **/
@import "_globals";

/**
 * Modules
 *
 * A module is a discrete component on the page. These are the modular,
 * reusable parts of our design, such as sections, key statistics, callouts, etc.
 * Related to Layouts, these are "minor" components on the page.
 **/

@import "modules/_academicons";
@import "modules/_altmetric";
@import "modules/_animations";
@import "modules/_author-perspective";
@import "modules/_avatar";
@import "modules/_background";
@import "modules/_lists"; // This must forever live above the boxes include.
@import "modules/_badges";
@import "modules/_banners";
@import "modules/_boxes";
@import "modules/_buttons";
@import "modules/_button-groups";
@import "modules/_card-deck";
@import "modules/_card-flip";
@import "modules/_checkboxes";
@import "modules/_editable-name";
@import "modules/_editable_fields";
@import "modules/_editing-help-text";
@import "modules/_font-share-icons";
@import "modules/_kudos-featured-image-panel";
@import "modules/_kudos-generate-shareable-link-box";
@import "modules/_kudos-hub-featured-publication";
@import "modules/_kudos-infographic-panel";
@import "modules/_kudos-popover-box";
@import "modules/_kudos_search_by_doi";
@import "modules/_glyphicons";
@import "modules/_grids";
@import "modules/_inline_lists";
@import "modules/_kpi";
@import "modules/_kudos_loader";
@import "modules/_labels";
@import "modules/_links";
@import "modules/_list-group";
@import "modules/_list-sort-order";
@import "modules/_loading-indicator";
@import "modules/_logos";
@import "modules/_navs";
@import "modules/_padding";
@import "modules/_panels";
@import "modules/_percentage-circle";
@import "modules/_photocredit";
@import "modules/_positioning";
@import "modules/_publication-label";
@import "modules/_publication-list";
@import "modules/_publication-overview";
@import "modules/_register_subscriptions";
@import "modules/_random_tiles";
@import "modules/_sections";
@import "modules/_shares_by_channel";
@import "modules/_social_media";
@import "modules/_tables";
@import "modules/_text";
@import "modules/_thumbnails";
@import "modules/_toast-notifications";
@import "modules/_trendmd";
@import "modules/_ui-grid";
@import "modules/_unsplash_credit";
@import "modules/_validation";
@import "modules/_width";

/**
 * Layouts
 *
 * Dividing the page up into sections, layouts hold modules together and form the
 * "major" components on the page.
 *
 * Examples include the header and footer sections.
 **/
@import "layouts/hub/_profile_about_me";
@import "layouts/hub/_profile_how_to_find_me";
@import "layouts/hub/_profile_featured_notice";
@import "layouts/hub/_profile_upsell_message";
@import "layouts/hub/_publications_add";
@import "layouts/hub/_publications_list";
@import "layouts/hub/_settings";
@import "layouts/hub/_tabs";
@import "layouts/hub/_trial_access";
@import "layouts/_about";
@import "layouts/_registration";
@import "layouts/_site-content";
@import "layouts/_site-header";
@import "layouts/_sidebar";
@import "layouts/_site-footer";
@import "layouts/_dashboards";
@import "layouts/_ie9";
@import "layouts/_publication";
@import "layouts/_publication_print";
@import "layouts/_static-page";
@import "layouts/_organisations_publications";
@import "layouts/_partners.scss";
@import "layouts/_public_profile.scss";
@import "layouts/_errors";
@import "layouts/_block-oauth-registration";
@import "layouts/_search_page.scss";
@import "layouts/_share_wizard";
@import "layouts/_signin";

@import "themes/_ux2.scss";
@import "themes/_ux3.scss";
@import "themes/_print.scss";

/**
  Angular-specific styling
**/

// Angular cloaking style (hides uncompiled content until Angular is ready)
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

// Required styling becasue we don't use href attributes on a elements, see http://angular-ui.github.io/bootstrap/#/getting_started
a {
  cursor: pointer;
}

/**
  Styles for global components
**/
ul.nav.nav-tabs {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  > li {
    > a,
    &.active > a {
      background: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom-width: 5px;

      font-weight: 700;
      color: $text-color;
      text-decoration: none;
    }

    &.active > a,
    &.active > a:focus {
      border-bottom-color: $kudos-blue;
    }
  }
}

.grey-border {
  border: 1px solid #cdcdcd;
}

/**
 Please don't add anything else to this file. All styles should be created as modules, following
 the SMACSS guidelines.
**/
