@mixin photocredit-position($vertical, $horizontal) {
  position: absolute;
  #{$vertical}: 5px;
  #{$horizontal}: 5px;
}

.photocredit-wrapper {
  position: relative;
}

.photocredit {
  &-top-left {
    @include photocredit-position(top, left)
  }
  &-top-right {
    @include photocredit-position(top, right)
  }
  &-bottom-left {
    @include photocredit-position(bottom, left)
  }
  &-bottom-right {
    @include photocredit-position(bottom, right)
  }
}
