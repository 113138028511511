.public-profile-page {
  .kudos-avatar {
    display: inline-block;
  }

  .coauthor-container {
    display: inline-block;
    margin-right: 20px;
    padding: 5px 0;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  .logo {
    margin-left: 3px;
    width: 29px;
  }

  .pp-name {
    font-weight: 700;
    font-size: 30px;
    color: $kudos-dark-grey;
  }

  .pp-subtext {
    font-size: 16px;
    color: $kudos-dark-grey;
  }

  .pp-title-heading {
    font-weight: 500;
    font-size: 18px;
    color: $kudos-dark-grey;
  }

  .publication-cards {
    .pub-item {
      background-color: white;
      border: 1px solid $kudos-light-grey;
      height: 100%;
      padding: 25px 20px;
      margin-bottom: 30px;

      &:hover,
      &:focus {
        background-color: #f8f8f8;
        cursor: pointer;

        .pub-title {
          text-decoration: underline;
        }
      }

      img {
        border: 5px solid $kudos-extra-light-grey2;
        max-width: 100%;
      }

      .pub-data {
        word-break: break-word;
      }

      .pub-title {
        color: $kudos-blue;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.22;
        margin-top: 0;
      }

      .pub-metadata {
        color: $kudos-dark-grey;
        font-size: 12px;
        font-weight: bold;
      }

      .pub-pls{
        color: $kudos-dark-grey;
        font-size: 12px;
      }
    }
  }

  .admin-buttons {
    button {
      margin: 0 10px;
    }
  }
}
