h4 {
  margin: 15px 0;
}

h6,
.h6 {
  font-weight: 700;
}

h1 {
  line-height: 1.125;
}

h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}

/**
  Text utilities and helper classes.
**/

// Use this class to prevent text from wrapping
// underneath images, etc. that it may be up against.
.hide-overflow {
  overflow: hidden;
}

.auto-overflow {
  overflow: auto;
}

// Causes long words to wrap inside their bounding box rather than protruding
.wrap-words {
  word-break: break-word;
  word-wrap: break-word; //for Firefox and IE

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-black {
  color: #000;
}

.text-grey {
  color: #908c8c;
}

.white-heading {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #fff;
  }
}

.jumbotron-smaller-heading {
  h1 {
    font-size: 30px;
  }
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.preserve-line-breaks {
  white-space: pre-wrap;
}

.nowrap {
  white-space: nowrap;
}

.font-size-small {
  font-size: $font-size-small;
  letter-spacing: normal; // Track out for smaller font size
}

.font-size-large {
  font-size: $font-size-large;
}

.strong {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.vertical-centre {
  vertical-align: middle;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.vertical-fill {
  display: flex;
  align-items: stretch;
}

.horizontal-fill {
  display: flex;
  justify-content: space-between;
}

@media (min-width: $screen-sm-min) {
  .vertical-align-sm {
    display: flex;
    align-items: center;
  }

  .vertical-fill-sm {
    display: flex;
    align-items: stretch;
  }
}

.capitalise:first-letter {
  text-transform: capitalize;
}

.break-word {
  overflow-wrap: break-word;
}

header {
  font-size: 1.5em;
}

// Pulled from application.scss
.subtitle {
  margin-top: -10px;
  margin-bottom: 1em;
}
