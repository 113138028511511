.list-group-borderless {
  margin: 0;

  .list-group-item {
    border-radius: 0;
    border-top-width: 1px;
    border-width: 0;

    &:first-child {
      border-top-width: 0;
    }
  }
}

.list-group-item-icon {
  padding-left: 40px;
  position: relative;

  .icon {
    height: 20px;
    left: 10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
  }
}
