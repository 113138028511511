.editable-name {

  // e.g. initials in a name inline form
  .narrow-form-field {
    width: 60px;
  }

  // Overide editiable button group field for inline form
  .editable-buttons {
    width: 100%;
  }

}
