.card-deck {
  -ms-transition: background-color 300ms ease-in-out;
  -webkit-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out;

  background: $kudos-light-grey;
  height: 380px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  display: block;

  &.completed {
    background-color: $brand-success !important;

    .completion-message {
      display: block;
    }
  }

  .completion-message {
    text-align: center;
    z-index: 9;
    margin-top: 95px;

    display: none;

    h2 {
      color: #fff;
    }

    .success-icon {
      font-size: 145px;
    }
  }

  .kudos-card {
    -ms-transition: -ms-transform 300ms ease-in;
    -webkit-transition: -webkit-transform 300ms ease-in;
    transition: transform 300ms ease-in;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &.completed {
      transform: translate(-1000px, 0) rotate(-20deg);
      -ms-transform: translate(-1000px, 0) rotate(-20deg);
      -webkit-transform: translate(-1000px, 0) rotate(-20deg);

      h2 {
        color: #fff;
      }
    }

    // create a cascading stack of cards
    $element_count: 4;
    @for $i from 0 to $element_count {
      &:nth-child(#{$i}) {
        z-index: 13 - $i;
      }
    }
  }
}
