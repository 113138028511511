ul.publication-list {
  padding: 0px;
  list-style-type: none;

  li {
    min-height: 80px;
    margin-top: 10px;

    img, svg {
      margin-right: 5px;
      max-height: 80px;
      width: 60px;
    }
  }

  li:first-child {
    margin-top: 0px;
  }
}
