featured-image-panel,
[featured-image-panel] {
  .search-input {
    margin: 10px;
    margin-left: 0px;
    width: 100% !important; // Override Bootstrap's auto width at viewports >= 768px
    max-width: 225px;
  }

  .search-wrapper {
    width: 100%;
  }

  .search-advice {
    ul {
      line-height: 2em;
    }
  }

  .photos {
    padding-bottom: 10px;
  }

  .image-preview {
    margin-bottom: 15px;
    margin-left: 15px;
    float: right;
    max-width: 30%;
    min-width: 100px;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }

    &.default-image {
      background-color: #e0e0e0;
      box-sizing: border-box;
      text-align: center;
      img {
        opacity: 0.1;
        padding: 10% 28%; // keep the same aspect ratio whilst being scaled down
      }
    }
  }

  .image {
    object-fit: cover; // makes a nice grid of equal-height images
    height: 100px;
    width: 150px;
    margin: 4px;
  }

  .btn-link {
    color: #6c757d;
  }

  .btn-fix-width {
    width: 120px;
  }
  .save-button {
    margin-left: 8px;
  }

  .selected {
    box-shadow: 0px 0px 3px 3px rgba(44, 132, 194, 1);
  }

  .page-previous,
  .page-next {
    height: 24px;
    width: 150px;
    margin: 0 8px;
    background-color: #edeeed;
    color: #333;
    &:hover,
    &:focus {
      background-color: #e0e0e0;
      &:disable {
        background-color: #edeeed;
      }
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      flex-grow: 1;
    }
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .submit-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    .submit-info {
      flex-grow: 1;
      flex-basis: 140px;
      margin-right: 10px;
    }

    .submit-buttons {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
