body.ux4 {
  .btn {
    border-radius: 9999px;
    transition-property: color, background-color, border;
    transition-duration: $default-animation-duration;
  }

  .btn-primary {
    background-color: black;
    border: 1px solid black;

    &:hover,
    &:focus {
      background-color: #333;
      border: 1px solid #333;
    }

    &:active {
      background-color: #555;
      border: 1px solid #555;
    }
  }

  .btn-muted {
    background-color: transparent;
    border-color: black;
    color: black;

    &:hover,
    &:focus {
      background-color: $kudos-extra-light-grey;
    }

    &:active {
      background-color: $kudos-light-light-grey;
      color: inherit;
      box-shadow: none;
    }
  }

  .btn-lg {
    font-size: 1rem;
  }

  .switch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 128px;
    height: 38px;
    border-radius: 19px;
    margin-top: 8px;
    color: #ffffff;
    background-color: $kudos-purple;
    border: 1px solid #ffffff;
    text-decoration: none;

    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active {
      background-color: $kudos-light-purple;
    }

    i {
      font-size: 1.125em;
    }
  }
}

kudos-publication-recommended-action {
  min-height: 190px;
}

.publication-reader-view {
  h1.micro-title {
    color: #000000;
    font-size: 1.9rem;
  }

  // Publication UX4 reskin, needs refactoring once complete
  div.ux4-publication-view {
    $view-border-radius: 10px;
    $vspace: 22px; // Basic unit for vertical spacing to maintain a consistent rhythm
    $banner-horizontal-padding: $vspace;
    $banner-md-horizontal-padding: 50px;

    @mixin hover-shadow {
      transition-property: transform, box-shadow;
      transition-duration: $default-animation-duration;

      &:hover,
      &:focus {
        transform: translateY(-3px);
        box-shadow: 2px 6px 7px 0 rgba(0, 0, 0, 0.2);
      }
    }

    display: grid;
    grid-template:
      [header-start]
      ". .       ." 2.5 * $vspace
      ". banner  ."
      ". .       ." 2.5 * $vspace
      [header-end]
      ". .       ."
      ". content ."
      ". sidebar ."
      ". complementary ."
      / 15px 1fr 15px;

    @media (min-width: $screen-sm-min) {
      grid-template:
        [header-start]
        ".              .             .              .             .             .             ." 2.5 * $vspace
        ".              banner        banner         banner        banner        banner        ."
        ".              .             .              .             .             .             ." 2.5 * $vspace
        [header-end]
        ".              .             .              .             .             .             ."
        ".              .             content        .             sidebar       .             ."
        "complementary  complementary complementary  complementary complementary complementary complementary"
        / minmax(15px, 1fr) $banner-horizontal-padding minmax(450px, 600px) 30px
        minmax(200px, 220px) $banner-horizontal-padding minmax(15px, 1fr);
    }

    @media (min-width: $screen-md-min) {
      $horizontal-padding: minmax(
        $banner-horizontal-padding,
        $banner-md-horizontal-padding
      );
      grid-template-columns:
        minmax(15px, 1fr) $horizontal-padding 652.5px 75px 212.5px
        $horizontal-padding minmax(15px, 1fr);
    }

    .banner-image {
      grid-column: 1/-1;
      grid-row: header-start/header-end;
      background-color: $kudos-extra-light-grey2;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .banner-image-credit {
      grid-column: sidebar-start/sidebar-end;
      grid-row: header-end/span 1;
    }

    heading {
      grid-area: banner;
      font-weight: 400;

      .project-type {
        border-radius: 1em;
        border: 1px solid;
        display: inline-block;
        padding: 0.125em 10px 0.25em;
        font-size: 0.875rem;
        letter-spacing: normal; // Track out for smaller font size.
      }

      .banner-content {
        padding: $vspace;

        background-color: #fff;
        border-radius: $view-border-radius;
        border: 1px solid $kudos-extra-light-grey2;

        .banner-title {
          margin: 15px 0px 10px 0px;
          h1 {
            color: #000000;
            font-size: 1.25rem;
          }

          p {
            margin-top: 5px;
          }
        }

        p {
          color: $kudos-dark-grey;
          margin: 0px;
          font-size: 0.875rem;
          letter-spacing: normal; // Track out for smaller font size
        }

        .journal-info {
          margin-top: $vspace;
        }

        a.doi-link {
          word-break: break-all;
        }
      }

      @media (min-width: $screen-sm-min) {
        .banner-content {
          .banner-title h1 {
            font-size: 1.875rem;
            letter-spacing: -0.025em; // Track in for larger font size
          }
        }
      }

      @media (min-width: $screen-md-min) {
        .banner-content {
          padding-left: $banner-md-horizontal-padding;
          padding-right: $banner-md-horizontal-padding;
        }
      }
    }

    nav.breadcrumbs {
      margin: 8px 0 1.5 * $vspace;

      @keyframes fadeIn {
        0% {
          opacity: 0;
          display: none;
        }

        100% {
          opacity: 1;
          display: block;
        }
      }

      @keyframes fadeOut {
        0% {
          opacity: 1;
          display: block;
        }

        100% {
          opacity: 0;
          display: none;
        }
      }

      grid-column: content-start / content-end;

      ol.breadcrumbs,
      a.up-link {
        padding: 4px 0;
      }

      a.up-link {
        display: flex;
      }

      #breadcrumb-overlay-positioning {
        display: none;
        position: relative;

        button {
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          height: 100%;
          width: min-content; // Workaround for a WebKit bug: https://bugs.webkit.org/show_bug.cgi?id=272527
          z-index: 100;
          padding: 0;
          background-color: white;
          border-radius: 9999px;
          border: 1px solid $kudos-extra-light-grey;
          aspect-ratio: 1;
          animation: fadeOut $base-transition-time / 2;
          align-items: center;
          justify-content: center;
          transition: background-color $default-animation-duration;

          img {
            height: 35%;
            width: 35%;
            object-fit: contain;
          }

          &:hover,
          &:focus {
            background-color: $kudos-ultra-light-grey;
          }

          &:active {
            background-color: $kudos-extra-light-grey;
          }
        }

        .left-fade,
        .right-fade {
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 100;
          width: 72px;
          animation: fadeOut $base-transition-time / 2;
        }

        button#scroll-left,
        .left-fade {
          left: 0;
        }

        .left-fade {
          background: linear-gradient(to right, white, white, transparent);
        }

        button#scroll-right,
        .right-fade {
          right: 0;
        }

        .right-fade {
          background: linear-gradient(to left, white, white, transparent);
        }

        &[data-first-breadcrumb-hidden] {
          > button#scroll-left,
          > .left-fade {
            display: flex;
            animation: fadeIn $base-transition-time / 2;
          }
        }

        &[data-last-breadcrumb-hidden] {
          > button#scroll-right,
          > .right-fade {
            display: flex;
            animation: fadeIn $base-transition-time / 2;
          }
        }

        ol.breadcrumbs {
          display: flex;
          list-style: none;
          margin: 0;
          overflow-x: scroll;
          scroll-behavior: smooth;

          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }

          @media (prefers-reduced-motion) {
            scroll-behavior: auto;
          }

          li {
            flex: none;

            display: flex;
            align-items: stretch;

            &.shrinkable {
              flex: 1 0 auto;
              width: 12ch;
              max-width: max-content;

              .breadcrumb-text {
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            &:last-child {
              .chevron {
                display: none;
              }
            }

            i {
              margin: 0 8px;
              font-size: 0.5em;
              vertical-align: middle;
            }
          }
        }
      }

      a.up-link {
        img {
          margin-inline-end: 8px;
          height: 1.5em;
        }
      }

      @media (min-width: $screen-xs-min) {
        a.up-link {
          display: none;
        }

        #breadcrumb-overlay-positioning {
          display: block;
        }
      }
    }

    main {
      grid-area: content;

      section {
        margin-bottom: 2 * $vspace;
      }

      p {
        font-size: 1rem;
        margin: 1em 0em;

        &.subtitle {
          margin: 0px;
          font-size: 0.875em;
          letter-spacing: normal; // Track out for smaller font size
          padding-top: 5px;
        }
      }

      h2 {
        font-size: 1.25rem;
        margin: 0 0 $vspace;
        text-align: center;

        @media (min-width: $screen-sm-min) {
          font-size: 1.875rem;
          letter-spacing: -0.025em; // Track in for larger font size
        }
      }

      .callout {
        display: flex;
        padding: $vspace 25px;
        background-color: $kudos-ultra-light-grey;
        border: 1px solid $kudos-light-grey;
        border-radius: $view-border-radius;

        .icon {
          margin-right: 15px;
          flex-shrink: 0;
        }

        .text {
          align-self: center;

          p {
            margin: 0 0 $vspace;
          }

          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .callout.ai-notice {
        border: 2px solid $color-innovation-blue-700;
        background-color: transparent;

        .icon {
          color: $color-innovation-blue-700;
        }
      }

      mag-ui-guest-user-job-role-collection {
        display: block;
        margin-bottom: $vspace;
      }

      #featured-image {
        margin-bottom: $vspace;
      }

      .btn {
        margin-top: 0.5 * $vspace;
      }

      ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li {
          a {
            text-decoration: none;
          }
        }
      }

      .perspective {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-bottom: 25px;
        padding: 0 5px;

        kudos-avatar {
          flex-shrink: 0;
          height: 60px;
          width: 60px;
        }

        .perspective-text {
          flex-grow: 1;
          padding: 0px 0px 0px 15px;
          margin: 0px;
          border-style: none;
          font-size: 1rem;

          .perspective-quote-container {
            display: flex;

            .quote-left {
              background-color: $kudos-ultra-light-grey;
              flex-shrink: 0;
              padding: 4px;
              font-size: 1.563rem;
              color: $kudos-dark-grey;
              width: 23px;
              display: flex;
              justify-content: center;
            }

            .perspective-quote {
              margin: 0;
              padding: 0 10px;
              flex-grow: 1;
            }

            .quote-right {
              align-items: flex-end;
            }
          }

          figcaption {
            color: $kudos-dark-grey;
            padding: 10px 0 10px 60px;
            display: inline-block;

            a {
              font-weight: 700;
            }

            .affiliation {
              font-size: 0.875rem;
              letter-spacing: normal; // Track out for smaller font size.
            }

            &:before {
              position: absolute;
              content: "";
              border-top: 1px solid $kudos-dark-grey;
              width: 3.4em;
              margin: 0 1em;
              transform: translate(-5.3em, 0.6em);
            }
          }
        }
      }

      .infographic {
        img {
          max-height: clamp(40px, 60vh, 800px);
          object-fit: contain;
        }

        .show-infographic-modal {
          text-align: center;

          a {
            font-size: 1rem;

            i {
              margin-right: 8px;
            }
          }
        }
      }

      .read-publication {
        padding: $vspace;
        border-radius: $view-border-radius;
        background-color: $kudos-ultra-light-grey;

        .read-publication-panel {
          display: flex;
          align-items: center;
          flex-direction: column;

          p {
            margin: 0;
          }

          cite {
            font-style: inherit;
            font-weight: bold;
          }

          .doi {
            word-break: break-all;
          }

          .read-publication-button {
            margin-bottom: 12px;
          }
        }

        .open-access {
          text-align: center;
        }

        @media (min-width: $screen-sm-min) {
          .read-publication-panel {
            flex-direction: row;

            .read-publication-button {
              margin: 16px;
              flex-shrink: 0;
              width: 150px;
            }
          }
        }
      }

      .resources {
        $resources-grid-gap: clamp(5px, 2vw, 15px);

        padding: $vspace;
        background-color: $kudos-ultra-light-grey;
        border-radius: $view-border-radius;

        ul {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          grid-gap: $resources-grid-gap;

          li {
            @include hover-shadow;

            background-color: #ffffff;
            border-radius: 5px;

            .resource {
              height: 270px;
              padding: 10px 10px 0 10px;
              text-align: left;

              .resource-type {
                display: flex;
                align-items: center;
                color: $kudos-dark-grey;

                svg {
                  width: 30px;
                  height: 30px;
                }

                span {
                  font-size: 0.875rem;
                  letter-spacing: normal; // Track out for smaller font size
                }
              }

              .resource-title,
              .resource-description {
                padding: 0 0 0 5px;
                font-size: 1rem;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              .resource-title {
                font-weight: bold;
                margin: 0.25 * $vspace 0;
                color: inherit;

                -webkit-line-clamp: 3;
              }

              .resource-description {
                -webkit-line-clamp: 5;
              }
            }
          }
        }
      }

      .contributors {
        $contibutor-width: 300px;

        .contributors-subtitle {
          text-align: center;
          color: $kudos-dark-grey;
          margin: $vspace 0;
        }

        .contributor-authors {
          ul {
            display: grid;
            grid-template-columns: repeat(
              auto-fill,
              minmax($contibutor-width, 1fr)
            );
            grid-auto-rows: 1fr;
            grid-gap: clamp(1px, 2vw, 20px);

            @media (min-width: $screen-sm-min) {
              justify-items: center;
            }

            li {
              .contributor-avatar {
                @include hover-shadow;

                display: flex;
                flex-direction: row;
                align-items: center;
                width: $contibutor-width;
                height: 100%;
                text-align: left;
                border-radius: 12px;
                padding: 10px;

                .contributor-avatar-text {
                  margin-left: 7px;

                  p {
                    margin: 0;
                  }

                  .contributor-affiliation {
                    line-height: 1.33;
                    font-size: 0.875rem;
                    letter-spacing: normal; // Track out for smaller font size
                    color: $kudos-dark-grey;
                  }
                }
              }
            }
          }
        }
      }
    }

    .recommendations {
      $max-container-width: 1260px;

      grid-area: complementary;
      max-width: $max-container-width;
      width: 100%;
      margin: auto;
      margin-bottom: 2 * $vspace;
      padding: $vspace 0;

      h2 {
        font-size: 1.25rem;
        margin: 0 0 $vspace;
        text-align: center;

        @media (min-width: $screen-sm-min) {
          font-size: 1.875rem;
          letter-spacing: -0.025em; // Track in for larger font size
        }
      }

      @media (min-width: $screen-sm-min) {
        background-color: $kudos-ultra-light-grey;
        padding: $vspace;
      }

      @media (min-width: $max-container-width) {
        border-radius: $view-border-radius;
      }

      .subject-showcase-links .card {
        @include hover-shadow;
        border-radius: $view-border-radius / 2;

        &:hover,
        &:visited {
          text-decoration: none;
        }
      }

      .subject-showcase-links {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
        grid-auto-rows: 200px;
        justify-content: center;

        @media (min-width: $screen-xs-min) {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          grid-auto-rows: 150px;
        }

        .card {
          background-color: #fff;
          border: 1px solid $kudos-dark-grey;
          text-decoration: none;

          .image-container {
            width: 100%;
            height: 70%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            height: 30%;
            overflow: hidden;

            h3 {
              font-size: 1rem;
              margin: 0;
            }
          }
        }
      }
    }

    .publication-sidebar {
      grid-area: sidebar;
      margin-bottom: 2 * $vspace;

      .sidebar-buttons > a:last-child {
        margin-bottom: 2 * $vspace;
      }

      .read-publication-button {
        display: none;

        @media (min-width: $screen-sm-min) {
          display: block;
        }
      }

      .fa-line-chart {
        font-size: 1.6em;
        line-height: 1.25;
      }

      .more-from-publisher {
        margin-top: $vspace;
        margin-bottom: 0em;
        box-shadow: none;
        border: 1px solid $kudos-light-grey;
        padding: 2rem 1rem;

        a {
          padding: 8px 12px;
          margin-bottom: 0px;
        }
      }
    }

    #resources {
      grid-area: resources;
      display: none;
    }

    .share-icons {
      h2 {
        font-size: 1rem;
        margin-bottom: 8px; // Share icons have their own padding
      }

      ul {
        margin: 0px auto;
        text-align: center;
      }
    }

    .publication-admin-controls {
      margin: 25px auto;
    }

    .btn {
      width: 100%;
      max-width: 230px;
      margin: 0 auto $vspace auto;
      padding: 16px;
      display: block;

      .btn-icon {
        vertical-align: middle;
        padding-right: 10px;
      }

      .btn-text {
        vertical-align: middle;
      }
    }
  }
}

.wip-banner {
  background-image: url("/images/publications/increase_impact_wip2x.jpeg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 200px auto;
  border: 2px solid #c8cac9;
  box-shadow: inset 0 0 0 1px #dddede;
  color: $kudos-blue;
  font-size: 22px;
  padding: 20px;
  padding-bottom: 240px;
  text-align: center;

  @media screen and (min-width: $screen-sm-min) {
    background-position: 18px center;
    background-size: 106px auto;
    font-size: 15px;
    padding: 38px;
    padding-left: 190px;
    text-align: left;
  }

  @media screen and (min-width: $screen-md-min) {
    background-position: 24px center;
    background-size: 150px auto;
    font-size: 22px;
    padding: 50px;
    padding-left: 280px;
  }
}

@mixin circle($size) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $size;
  width: $size;
  border-radius: $size;
}
// Flash the Recommended action box
#recommended-action {
  .panel-heading,
  .panel-body {
    -webkit-transition: background-color 100ms ease-in-out;
    -moz-transition: background-color 100ms ease-in-out;
    -o-transition: background-color 100ms ease-in-out;
    transition: background-color 100ms ease-in-out;
  }
}

.activity-flash {
  .panel-body {
    animation-name: colourChange;
    animation-duration: 3.5s;
  }
}

@keyframes colourChange {
  0% {
    background-color: $kudos-orange;
    opacity: 0.3;
  }
  100% {
    background-color: white;
    opacity: 1;
  }
}

.publication-author-view {
  textarea {
    resize: vertical;
  }

  header {
    padding-bottom: 8px;

    p {
      font-size: 1rem;
    }
  }

  .pdf-thumb {
    border: 1px solid $kudos-light-grey;
    margin-right: 10px;
    width: 110px;
  }

  geographic-regions-panel {
    label {
      margin-left: 6px;
      font-weight: normal;
    }

    a {
      margin-left: 25px;
    }

    .checkboxes {
      margin-top: 20px;
      input {
        margin-left: 20px;
      }
    }
  }

  .character-count {
    p {
      display: inline;
      line-height: 1.8;
      margin-right: 5px;
      vertical-align: top;
    }

    .success-check {
      font-size: 20px;
      line-height: 1;
    }

    @media screen and (min-width: $screen-xs-min) {
      position: absolute;
    }
  }

  showcase-your-work-panel {
    $showcase-image-height: 115px;

    .hidden-xs {
      text-align: center;
    }

    img {
      height: $showcase-image-height;

      &#complete {
        margin: 0 -10px;
      }
    }

    .list-reset {
      // Reset ul styles.
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .showcase-complete {
      height: $showcase-image-height;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .getting-started {
      color: $text-color;
      h3 {
        color: $kudos-dark-grey;
        font-size: 16px;
        margin-top: 0;
      }
    }

    #feature-work {
      margin: 5px 0 0 0;
      text-align: center;
      font-size: 0.875rem;
    }

    #showcase-link {
      display: block;
    }

    $counter: step-counter;
    $number-circle-size-normal: 30px;

    $incomplete-color: $kudos-dark-grey;
    $complete-color: $kudos-bright-green;

    .clicker {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      &::before {
        flex-shrink: 0;
        font-size: 20px;
        color: white;
        line-height: 1;
        background-color: $incomplete-color;
        @include circle($number-circle-size-normal);
      }
      &.complete::before {
        background-color: $complete-color;
      }

      .subtitle {
        display: block;
        margin: 0;
      }

      $padding: 6px;
      padding: $padding;
      padding-right: $padding + 10px;
      margin-left: -$padding;
      border-radius: $number-circle-size-normal + ($padding * 2);
      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: $kudos-extra-light-grey2;
        }
      }
      &.hoverable {
        &:hover {
          background-color: $kudos-extra-light-grey2;
        }
      }

      > * {
        margin-left: 13px;

        &.title {
          flex-shrink: 1;
        }
      }

      .btn {
        font-size: 11pt;
        min-width: 86px;
      }
    }

    ol {
      list-style: none;
      counter-reset: $counter;

      li {
        counter-increment: $counter;
        margin-bottom: 2px;
        .clicker::before {
          content: counter($counter);
        }
      }
    }

    .title {
      font-size: 16px;
      color: $incomplete-color;
      .clicker.complete & {
        color: $complete-color;
      }
    }

    .icon {
      font-size: 2.2em;
    }
    .tick {
      color: $complete-color;
    }
  }

  publication-resources {
    .add-resources-feature-info {
      margin-top: 1em;
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5em;
      column-gap: 1em;

      img {
        flex: 0 0 auto;
      }

      .add-resources-message {
        display: flex;
        flex: 1 1 max-content;
        align-items: center;
        column-gap: 0.5em;
      }

      .add-resources-message button {
        margin-left: auto;
        flex: 0 0 auto;
      }

      .add-resources-message > * {
        margin: 0.25em 0;
      }
    }
  }
}
