// This assumes that padding/margin is only applied to the top of the element
// TODO add global reset + add to kudos style guide

.ux3 {
  $kudos-extra-light-grey: #ebf0f2;
  $state-info-text: #4784c3;

  .small {
    font-size: $font-size-small;
    letter-spacing: normal; // Track out for larger font size
  }

  // Overriden in preparation for ux3 theme
  h1,
  .h1 {
    font-size: 1.25em;
    font-weight: 700;
    color: $kudos-orange;
  }

  h2,
  .h2 {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 0;
  }

  h3,
  .h3 {
    color: $state-info-text;
  }

  h4,
  .h4 {
    color: $kudos-orange;
  }

  h5,
  .h5 {
    font-weight: 700;
  }

  .extra-light-grey-background {
    background-color: $kudos-extra-light-grey;
  }

  .author-perspective-relationship {
    font-weight: 500;
  }

  .subtitle {
    color: $text-muted;
    margin-top: 0.41em;
  }

  .list-unstyled {
    margin-top: 0.83em;
    font-size: 0.875rem;
  }

  .small-top-margin {
    margin-top: 4px;
  }

  .navbar {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 4px solid $brand-primary;
    box-sizing: content-box;
  }
}
