@mixin avatar($size) {
  max-width: $size;
  max-height: $size;
}

@mixin circular {
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  &.avatar-icon {
    @include avatar(30px);
    @include circular;
  }

  &.avatar-default {
    @include avatar(60px);
    @include circular;
  }

  &.avatar-profile {
    @include avatar(120px);
    @include circular;
  }
}

kudos-avatar {
  .avatar {
    img, svg {
      vertical-align: middle;
      @include circular;
    }

    &.avatar-small {
      img, svg {
        @include avatar(30px);
      }
    }

    &.avatar-medium {
      img, svg {
        @include avatar(60px);
      }
    }

    &.avatar-large {
      img, svg {
        @include avatar(120px);
      }
    }

    &.avatar-xlarge {
      img, svg {
        @include avatar(175px);
      }
    }

    &.with-shadow {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      // To have the inset box-shadow appear over the avatar img or svg, it's
      // simpler to have a pseudo-element appear over the top. Another way would
      // be to give the img/svg a negative z-index, but that can have issues in
      // some places (e.g. deeply nested in stacking contexts).
      &::after {
        content: '';
        box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        @include circular;
      }
    }
  }
}
