.static-page {
  padding: 20px;

  h1 {
    color: #000;
    font-weight: 700;
  }

  h2 {
    color: $kudos-orange;
    padding-bottom: 15px;
  }

  p,
  ul {
    font-size: 1.333333333em;

    ul {
      // Stop nested lists getting larger and larger font size, since the
      // font-size in em units above applies multiplicatively.
      font-size: 1em;
    }
  }

  .container {
    padding: 25px 0;
  }
}

.release-notes-page {

  img {
    border: 1px solid $kudos-light-grey;
    margin-top: 30px;
  }

  .action-image {
    border: 0;
    width: 70%;
  }
}

.careers-page {
  p {
    margin: 10px 0;
  }

  .subheading {
    font-weight: bold;
  }

  .underline-box {
    border-bottom: 1px #000 solid;
    padding-bottom: 15px;
  }

  .row-bottom-space {
    padding-bottom: 50px;
    margin-bottom: 50px;
  }

  .center-block {
    display:block;
    margin:auto;
    max-width: 100%;
  }
}
