.page-error {
  background-color: #fff;
  background-image: url('/images/404_page_background.jpg');
  background-position: center 20%;

  .panel {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
