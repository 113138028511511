kudos-share-multi-channel {
  display: block;

  .panel {
    @include box-shadow(none);
    margin: 0;
  }

  // Reset user-agent styles for elements.
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: none;
      border: none;
      padding: 0;
    }
  }

  legend {
    font-size: inherit;
  }

  // Use classes for this module's styles.
  .share-channel-list {
    --min-column-width: 120px;

    margin: 10px 0;
    display: grid;
    // Sass is case-sensitive, while CSS isn't, so we can use the CSS min()
    // function by capitalizing it:
    grid-template-columns: repeat(
      auto-fit,
      minmax(Min(var(--min-column-width), 100%), 1fr)
    );
    grid-auto-rows: 1fr;
    justify-items: center;
    gap: 12px 4px;

    @media (min-width: $screen-xs-min) {
      --min-column-width: 130px;
      row-gap: 20px;
    }
  }

  .share-channel-list-item {
    width: 100%;
    height: 100%;
    position: relative;

    input[type="radio"] {
      // Keep the radio input in the accessibility tree, but hide it:
      position: absolute;
      margin: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    label {
      font-weight: normal;
      border: 4px solid transparent;
      border-radius: 8px;
      padding: 10px 5px;
      margin: 0;
      cursor: pointer;
    }

    input:hover + label,
    input:active + label {
      background-color: $kudos-extra-light-grey2;
    }

    input:checked + label {
      font-weight: bold;
      border-color: $kudos-blue;
      background-color: $kudos-extra-light-grey2;
    }

    input:focus-visible + label {
      outline: 2px solid $kudos-blue;
      outline-offset: 2px;
    }
  }

  .share-channel {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .share-icon {
    margin-bottom: 5px;
    font-size: 1.75em;

    @media (min-width: $screen-xs-min) {
      font-size: 2em;
    }
  }

  .share-channel-link .share-icon {
    color: $kudos-orange;
  }

  .share-channel-researchgate .share-icon {
    color: $brand-researchgate-color;
  }

  .share-channel-pdf .share-icon {
    color: $kudos-deep-red;
  }

  .share-channel-twitter .share-icon {
    color: $brand-twitter-color;
  }

  .share-channel-facebook .share-icon {
    color: $brand-facebook-color;
  }

  .share-channel-linkedin .share-icon {
    color: $brand-linkedin-color;
  }

  .share-channel-weibo .share-icon {
    color: $brand-weibo-color;
  }

  .share-channel-wechat .share-icon {
    color: $brand-wechat-color;
  }

  .share-channel-mastodon .share-icon {
    color: $brand-mastodon-color;
  }

  .share-channel-bluesky .share-icon {
    color: $brand-bluesky-color;
  }

  .share-channel-whatsapp .share-icon {
    color: $brand-whatsapp-color;
  }
}
