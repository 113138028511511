.btn-group-flex {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;

  .btn {
    flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;

    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.btn-group-spaced-apart {
  display: flex;
  justify-content: space-between;
}

.btn-group-with-label {
  > .btn:first-of-type:not(:last-of-type):not(.dropdown-toggle) {
    border-radius: $btn-border-radius-base;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.btn-sm {
      border-radius: $btn-border-radius-small;
    }
  }
}

.btn-label {
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  border-radius: $btn-border-radius-base;
  float: left;

  &.btn-label-lg {
    padding: $padding-large-vertical $padding-large-horizontal;
    font-size: $font-size-large;
    line-height: $line-height-large;
    border-radius: $btn-border-radius-large;
  }

  &.btn-label-sm {
    padding: $padding-small-vertical $padding-small-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    border-radius: $btn-border-radius-small;
  }

  &.btn-label-xs {
    padding: $padding-xs-vertical $padding-xs-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    border-radius: $btn-border-radius-small;
  }
}
