// Apply a natural box layout model to all elements, but allowing components to change
// See http://www.paulirish.com/2012/box-sizing-border-box-ftw/
html {
  box-sizing: border-box;
  min-height: 100%;

  // Allow rem units to work as expected (Bootstrap will set the same size on
  // the body element):
  font-size: $font-size-base;

  letter-spacing: -0.0125em;
  font-synthesis: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.with-static-header {
  padding-top: $navbar-height;
}

// Remove heading margin from panels (it just looks weird)
.panel-body > h1,
.panel-body > h2,
.panel-body > h3,
.panel-body > h4 {
  margin-top: 0;
}

// Make toasts wider
#toast-container > div {
  width: 100%;
}

img {
  // IE makes scaled images look horrendous by default. See https://css-tricks.com/ie-fix-bicubic-scaling-for-images/.
  -ms-interpolation-mode: bicubic;
}

textarea.form-control:focus {
  border-color: $kudos-blue;
  box-shadow: inset 0 0 5px 2px rgba($kudos-blue, 0.75);
}
