.premium-profile-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  .btn {
    flex: 1 0;
  }

  @media (min-width: $screen-sm-min) {
    .btn {
      flex: 0 0;
    }
  }
}
