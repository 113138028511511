// This file contains the variables used throughout the Kudos UI.

$icon-font-path: "/fonts/";
$fa-font-path: "/fonts";

$resource-icon-path: "/assets/resource_icons/";

$kudos-light-blue: #e9f1f3;
$kudos-blue: #337ab7;
$kudos-blue-dark: #286090;
$kudos-green: #7f9818;
$kudos-bright-green: #4d8c1c;
$kudos-light-orange: #ffa100;
$kudos-orange: #ed5c25;
$kudos-ultra-light-grey: #f9f9f9;
$kudos-extra-light-grey: #ebf0f2;
// TODO: replace above with below
$kudos-extra-light-grey2: #edeeed;
$kudos-light-light-grey: #e0e0e0;
$kudos-light-grey: #ccc;
$kudos-grey: #8e8e8e;
$kudos-dark-grey: #616161;
$kudos-violet: #8234be;
$kudos-red: #ff0000;
$kudos-deep-red: #c30402;
$kudos-fuschia-pink: #d6185a;
$kudos-purple: #6f5ec2;
$kudos-light-purple: #7e6de5;

// Magazine UI colors
$color-wisdom-blue-100: #e7f5f8;
$color-wisdom-blue-200: #cfeaf0;
$color-wisdom-blue-300: #b7e0e9;
$color-wisdom-blue-500: #87cbda;
$color-wisdom-blue-700: #44aec5;
$color-wisdom-blue-900: #2a7687;
$color-wisdom-blue: $color-wisdom-blue-500;
$color-motivation-green-100: #f2f0e4;
$color-motivation-green-200: #e4e1c8;
$color-motivation-green-300: #d7d3ad;
$color-motivation-green-500: #bcb576;
$color-motivation-green-700: #9a924b;
$color-motivation-green-900: #676232;
$color-motivation-green: $color-motivation-green-500;
$color-passion-pink-100: #f3dae3;
$color-passion-pink-200: #e7b4c7;
$color-passion-pink-300: #dc8faa;
$color-passion-pink-500: #c44472;
$color-passion-pink-700: #972f55;
$color-passion-pink-900: #642038;
$color-passion-pink: $color-passion-pink-500;
$color-innovation-blue-100: #e7e7f8;
$color-innovation-blue-200: #cfcff0;
$color-innovation-blue-300: #b6b6e9;
$color-innovation-blue-500: #8787da;
$color-innovation-blue-700: #4343c5;
$color-innovation-blue-900: #292987;
$color-innovation-blue: $color-innovation-blue-500;
$color-creativity-orange-100: #fef3e5;
$color-creativity-orange-200: #fee7cc;
$color-creativity-orange-300: #fddbb2;
$color-creativity-orange-500: #fcc37f;
$color-creativity-orange-700: #fa9822;
$color-creativity-orange-900: #b96704;
$color-creativity-orange-1000: #a05903;
$color-creativity-orange: $color-creativity-orange-500;

$base-transition-time: 300ms;

// Bootstrap variables

// // Merges md and lg breakpoints
$grid-gutter-width: 30px;

// Media query breakpoints
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: $screen-md-min;

// Bootstrap container sizes
$container-large-desktop: (940px + $grid-gutter-width);
$container-lg: $container-large-desktop;

$text-color: #000;
$font-family-sans-serif: "Messina Sans", system-ui, -apple-system, Segoe UI,
  Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue;
$font-size-base: 16px;
$line-height-base: 24px / $font-size-base;

$font-size-large: ceil(($font-size-base * 1.125)) !default;
$font-size-small: ceil(($font-size-base * 0.875)) !default;

$font-size-h1: floor(($font-size-base * 1.875)) !default;
$font-size-h2: floor(($font-size-base * 1.5)) !default;
$font-size-h3: ceil(($font-size-base * 1.25)) !default;
$font-size-h4: ceil(($font-size-base * 1.125)) !default;
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.875)) !default;
$headings-color: $kudos-dark-grey;

// Navbar breakpoint for collapse, let's just get rid of auto-collapse by using 0.
$grid-float-breakpoint: 0;
$navbar-height: 50px;

$navbar-default-color: $kudos-dark-grey;
$navbar-default-bg: rgba(255, 255, 255, 1);
$navbar-default-border: darken($navbar-default-bg, 6.5%) !default;

$navbar-default-link-color: $kudos-dark-grey;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

$link-hover-darken: 6.5%;

$panel-border-radius: 4px;
$panel-heading-text: white;
$panel-heading-link-hover: $kudos-light-blue;

// Make all btn-success kudos green
$btn-success-bg: $kudos-green;

// Site-wide colours
.blue {
  color: $kudos-blue;
}
.blue-background {
  background-color: $kudos-blue;
  color: #fff;
}

.green {
  color: $kudos-green;
}
.green-background {
  background-color: $kudos-green;
  color: #fff;
}

.bright-green {
  color: $kudos-bright-green;
}

.orange {
  color: $kudos-orange;
}
.orange-background {
  background-color: $kudos-orange;
  color: #fff;
}

.light-gray {
  color: $kudos-light-grey;
}
.light-gray-background {
  background-color: $kudos-light-grey;
  color: #fff;
}

.gray {
  color: $kudos-grey;
}
.gray-background {
  background-color: $kudos-grey;
  color: #fff;
}

.dark-grey {
  color: $kudos-dark-grey;
}
.dark-grey-background {
  background-color: $kudos-dark-grey;
  color: #fff;
}

.white {
  color: #fff;
}
.white-background {
  background-color: white;
}

.violet {
  color: $kudos-violet;
}

.red {
  color: $kudos-red;
}

$avatar-width: 60px;
$avatar-height: $avatar-width;

$flag-icon-css-path: "/flag-icons";
$flag-icon-rect-path: "/4x3";
$flag-icon-square-path: "/1x1";

// Social media colors
$social-blog-color: #f26109;
$social-email-color: #ffa100;

// Third-party brand colors
$brand-researchgate-color: #40ba9b;
$brand-facebook-color: #1877f2;
$brand-twitter-color: black;
$brand-linkedin-color: #0a66c2;
$brand-weibo-color: #df2029;
$brand-wechat-color: #09b83e;
$brand-mastodon-color: #6364ff;
$brand-bluesky-color: #1185fe;
$brand-whatsapp-color: #25d366;
$brand-academia-color: #41454a;
$brand-mendeley-color: #e5222e;
$brand-youtube-color: #ff0000;
$brand-slideshare-color: #026c97;

// Share channels
$channels-other-color: #a60503;
$channels-inactive-color: #e0e0e0;

$default-animation-duration: 200ms; // Standard duration for a zippy-feeling animation
