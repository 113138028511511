.register-subscriptions {
  hr {
    margin-bottom: 0;
  }

  legend {
    border: inherit;
    color: inherit;
    font-size: inherit;
    &,
    p {
      margin-bottom: 0;
    }
  }

  label {
    display: block;
    margin-top: 10px;
  }
}
