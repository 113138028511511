.partners-page {
    .partner-logo {
        width: 170px;
        margin: 15px 0;
    }
    .vertical-align{
        @media screen and (max-width: $screen-xs-min){
            display: initial;
        }
    }
}
