.social-media-panel {
  .oauth-provider-connect {
    .vertical-padding {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .oauth-provider-connect-icon {
      padding-left: 10px;
      padding-right: 0;
    }

    .oauth-provider-connect-btn {
      padding-left: 5px;
      padding-right: 0;
    }

    .oauth-provider-connected-btn {
      padding-left: 5px;
      padding-right: 0;
      .btn {
        cursor: default;
      }
    }

    .oauth-provider-remove-btn {
      padding-left: 0;
      padding-right: 0;
      .btn-link {
        margin-bottom: 0;
      }
    }
  }
}

.change-password-panel {
  .password-feedback {
    cursor: pointer;
    pointer-events: all;
    right: 3px;
  }
}

.page-hub-settings {
  label {
    font-weight: normal;
  }
}
