$search-mobile-button-width: 38px;
$search-mobile-button-pull-left: 10px;

.search-page {
  .blank-search {
    width: 100%;
    min-height: 85vh;
    padding-top: 13rem;
    padding-bottom: 10rem;
    margin-bottom: 0;
  }

  .form-control {
    display: inline-block;
    width: calc(100% - (#{$search-mobile-button-width} - #{$search-mobile-button-pull-left}));

    @media (min-width: $screen-sm-min) {
      margin: 0 10px 10px 0;
      width: 80%;
    }
  }

  .btn {
    vertical-align: unset;
  }

  .btn-visible-mobile {
    margin-left: -$search-mobile-button-pull-left;
    width: $search-mobile-button-width;

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  .btn-visible-desktop {
    @media (max-width: $screen-sm-min - 1px) {
      display: none;
    }
  }

  .result-list {
    list-style-type: none;
    padding: 0;
  }

  .result {
    border-bottom: 1px $kudos-light-grey solid;
    margin: 25px 0;
    padding-bottom: 20px;
  }

  .result-title {
    font-size: 1.4em;
  }

  .result-container-title,
  .result-authors {
    color: $kudos-dark-grey;
  }
}
