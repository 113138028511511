kudos-hub-featured-publication {
  .star {
    color: $kudos-grey;
    cursor: pointer;
    font-size: 20px;

    &:hover,
    &:focus {
      color: $kudos-light-orange;
    }

    &.selected {
      color: $kudos-orange;
    }

    &.waiting {
      cursor: wait;
      opacity: 0.6;
    }
  }
}
