/**
  Useful mixins
**/

// Standard sizing for share icons
@mixin share-icon($width: 23px, $height: 23px) {
  display: inline-block;
  vertical-align: bottom;
  width: $width;
  height: $height;
  cursor: pointer;
  a {
    width: $width;
    height: $height;
  }
}

// Standard sizing for font-awesonme share icons
@mixin font-share-icon($width: 23px, $height: 23px) {
  @include share-icon($width, $height);
  line-height: $height;

  &:before {
    font-size: $height;
    width: $width;
    height: $height;
  }
}

// BootStrap Table Row Override

@mixin table-row-variant($state, $background, $text-color:false) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $background;
      @if $text-color != false {
        color: $text-color;
      }
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td,
    &:hover > .#{$state},
    &.#{$state}:hover > th {
      background-color: darken($background, 5%);
      @if $text-color != false {
        color: $text-color;
      }
    }
  }
}

@mixin fullpage {
  width: 100%;
  min-height: 85vh;
  padding-top: 15rem;
  padding-bottom: 10rem;
  margin-bottom: 0;
}

@mixin fullpage-background($background-image) {
  @include fullpage;
  position: relative;
  background-image: url($background-image);
  background-color: #fff;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  color: #fff;
  text-align: center;
}
