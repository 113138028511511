$trial-access-color: #fff;
$trial-access-border-radius: 4px;
$trial-access-bg-primary: #4343c5;
$trial-access-bg-image: url("/images/banners/premium_banner_background.jpg");
$trial-access-bg-secondary: #fff;

.trial-access {
  background-color: $trial-access-bg-primary;
  background-image: $trial-access-bg-image;
  background-size: cover;
  border-radius: $trial-access-border-radius;

  h4,
  p {
    margin: 0;
    padding: 0;
    color: $trial-access-color;
  }

  .trial-access-title {
    color: $trial-access-color;
    font-weight: bold;
  }

  .trial-access-btn {
    font-size: 14px;
    background-color: #fff;
    color: #000;
    padding: 10px 22px;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

.trial-access-notice {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  border: 2px solid #fff;

  .trial-access-notice-info {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-basis: 70%;

    h4 {
      font-size: 1.15em;
      margin: 10px 5px 0 10px;
    }

    p {
      margin: 5px 0 10px 10px;
    }
  }

  .trial-access-notice-action {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-basis: 33%;
    text-align: center;
    background-color: $trial-access-bg-secondary;
    color: #000;

    p {
      padding: 0 40px;
      color: inherit;
    }
  }
}

.trial-access-banner {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 1em 0 0 0;
  border: 1px solid #616161;

  .trial-access-banner-info {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-basis: 70%;

    h4 {
      font-size: 1.15em;
      margin: 10px 0 10px 10px;
    }

    p {
      margin: 0 0 10px 10px;
    }
  }

  .trial-access-banner-action {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-basis: 30%;
  }
}

.trial-access-panel {
  margin-bottom: 17px;
  padding-bottom: 16px;
  text-align: center;
  background-color: #fff;
  background-image: none;
  color: #000;
  border: 1px solid;

  .trial-access-title {
    margin-bottom: 16px;
    padding: 8px;
  }

  .trial-access-img,
  p {
    margin: 24px 0;
  }

  img {
    width: 60%;
    height: auto;
  }

  .trial-access-title {
    background-color: #000;
    color: #fff;
  }

  p {
    color: #000;
    padding: 0 24px;
  }

  .trial-access-btn {
    background-color: #000;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

.trial-access-notice-lite {
  background-color: $trial-access-bg-secondary;
  border: 2px solid #fff;
  p {
    margin: 5px;
  }
}

.trial-access-banner-lite {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .trial-access-banner-info {
    margin: 0 0 0 10px;
  }

  .trial-access-banner-info,
  .trial-access-banner-action {
    padding: 8px;
  }

  @media screen and (max-width: $screen-xs-min) {
    .trial-access-banner-info > h4,
    .trial-access-btn {
      font-size: 1em;
    }
  }
}
