.unsplash-credit {
  background-color: #000;
  border-radius: 3px;
  bottom: 0;
  color: #fff;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  left: 0;
  line-height: 1.2;
  padding: 4px 6px;
  position: absolute;
  text-decoration: none;

  span {
    display: inline-block;
    padding: 2px 3px;
  }
}

.credit-svg {
  fill: #fff;
  height: 12px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: auto;
}
