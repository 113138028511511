
// A generic in-line list
// DEPRECATED
// USE list-inline in preference as it's a Bootstrap standard class
ul.inline-list {
  display: inline;
  list-style-type: none;
  padding-left: 0;

  >li {
    display: inline-block;
  }
}

ul.inline-list--padded {
  @extend ul.inline-list;
  >li {
    padding-right: 1em;
  }
}

ul.inline-list--commas {
  @extend ul.inline-list;
  margin: 0;
  padding: 0;
  >li {
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
  }
}

// Extends Bootstrap list-inline
.list-inline-extra-padding > li:not(:last-child) {
  padding-right: 3rem;
}
.list-inline-padding > li:not(:last-child) {
  padding-right: 2rem;
}
.list-inline-vertical-padding > li {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.list-inline-align-left > li {
  padding-left: 0;
}
.list-inline-unpadded {
  margin-bottom: 0;
  &> li {
    padding-left: 0;
    padding-right: 0;
  }
}

.list-inline-block {
  display: inline-block;
  padding-left: 0;
}


// Use box lists for inline boxes with differing content sizes. For more info see
// http://osvaldas.info/flexbox-based-responsive-equal-height-blocks-with-javascript-fallback
.box-list
{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.box-list__item
{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  padding-left: 15px;
  padding-right: 15px;
}
