.font-share-icon {
  @include font-share-icon(30px, 30px);
  font-family: FontAwesome;
  position: relative;

  &:before {
    transition: color $base-transition-time;
    position: absolute;
    top: 0;
    left: 0;
    color: $kudos-dark-grey;
    text-align: center;
  }

  &:hover {
    &:before {
      color: #000;
    }
  }

  &.icon--facebook {
    &:before {
      content: '\f082';
    }
  }

  &.icon--twitter {
    &:before {
      content: '\f081';
    }
  }

  &.icon--email {
    &:before {
      content: '\f0e0';
    }
  }

  &.icon--gplus {
    &:before {
      content: '\f0d4';
    }
  }

  &.icon--linkedin {
    &:before {
      content: '\f08c';
    }
  }

}
