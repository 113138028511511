kudos-search-by-doi {
  .claim-button-group {
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 10px;

    > * {
      flex: 1;
    }

    kudos-publication-claim-button {
      button {
        // Needed to make the button follow the size of the Angular component
        // element, which is the flex item.
        width: 100%;
      }
    }
  }
}
