.publication-label {
  height: 25px;

  margin: 0;
  position: relative;
  padding-left: 30px;

  color: #000 !important;

  .icon {
    color: $kudos-orange;
    font-size: 25px;
    position: absolute;
    top: -10px;
    left: 0;
  }
}
