.publication-overview {
  .publication-overview-title {
    // 14px
    font-size: 1.16em;
    font-weight: 300;
  }

  .publication-overview-authors {
    padding-bottom: 1em;
  }
}
