.grid-container {
  padding-top: 10px;
  padding-bottom: 10px;

  .search-grid {
    width: 940px;
  }
}

.row-section {
  padding-top: 2em;
  padding-bottom: 0.5em;
}

.row-equal-height {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;

  > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
}
