.ui-grid-cell.ui-grid-disable-selection.ui-grid-row-header-cell {
  pointer-events: all !important;
}

.wrap-words {
  .ui-grid-cell-contents {
    white-space: pre-wrap;
  }
}

.ui-grid-invisible {
  display: none;
}

@font-face {
  font-family: "ui-grid";
  src: url("#{$icon-font-path}ui-grid.eot");
  src: url("#{$icon-font-path}ui-grid.eot#iefix") format("embedded-opentype"),
       url("#{$icon-font-path}ui-grid.woff") format("woff"),
       url("#{$icon-font-path}ui-grid.ttf") format("truetype"),
       url("#{$icon-font-path}ui-grid.svg?#ui-grid") format("svg");
}
