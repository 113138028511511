.random-tiles-container {
  margin-bottom: 20px;
  width: 100%;
  display: block;
  overflow: overlay;
}

.random-tile {
  display: inline-block;
  border: 1px solid $kudos-light-grey;
  width: 45%;
  margin: 2%;
  height: 120px;

  @media (min-width: $screen-sm-min) {
    width: 18%;
    margin: 1%;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.random-tile-image {
  margin: 5%;
  width: 90%;
  height: 85%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.fade-in {
    animation-duration: 1000ms;
  }
}

