.extra-padding-left {
  padding-left: 14px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.small-bottom-padding {
  padding-bottom: 5px !important;
}

.medium-bottom-padding {
  padding-bottom: 10px !important;
}

.x-large-bottom-padding {
  padding-bottom: 30px !important;
}

.x-large-top-padding {
  padding-top: 30px !important;
}

.medium-top-padding {
  padding-top: 10px !important;
}

.large-left-padding {
  padding-left: 20px !important;
}

.medium-left-padding {
  padding-left: 10px !important;
}

.small-left-padding {
  padding-left: 5px !important;
}

.small-right-padding {
  padding-right: 5px !important;
}

.medium-right-padding {
  padding-right: 10px !important;
}

.medium-top-bottom-padding {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.large-top-bottom-padding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.small-padding {
  padding: 5px !important;
}

.medium-padding {
  padding: 10px;
}

.large-padding {
  padding: 20px;
}

// Useful to add margin spacing above elements
// which would usually sit in-line, e.g. buttons
.margin-top {
  margin-top: 1em;
}

.margin-top-medium {
  margin-top: 2em;
}

.margin-top-small {
  margin-top: 0.5em;
}

.margin-bottom-small {
  margin-bottom: 0.5em;
}

.margin-bottom {
  margin-bottom: 1em !important;
}

.margin-bottom-medium {
  margin-bottom: 2em;
}

.margin-top-large {
  margin-top: 3em;
}

.margin-bottom-large {
  margin-bottom: 3em;
}

.margin-top-none {
  margin-top: 0;
}

.margin-none {
  margin: 0 !important;
}

// Used in api widgets, pulled out of application.scss
.with-bottom-small-margin {
  position: relative;
  margin-bottom: 15px;
}

// Pulled out of application.scss
.with-bottom-padding {
  position: relative;
  padding-bottom: 35px;
}

.in-bottom-padding--right {
  position: absolute;
  bottom: 10px;
  right: 10px;
 }

.no-bottom-margin {
  margin-bottom: 0;
}

// Add a gutter to the right-hand edge of the element.
// Useful for columns and text blocks requiring prominence,
// for example the central swimlane on the publication page
.gutter-right {
  @media (min-width: $screen-md-min) {
    padding-right: 5 * $padding-base-horizontal;
  }
}
