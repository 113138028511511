// Some of the colours here are different to the respective brand guidelines.
// See _brands.scss for latest colours.

@mixin social-link($colour) {
  color: $colour;
  &:hover {
    color: darken($colour, $link-hover-darken);
  }
}

.social-facebook-link {
  @include social-link($brand-facebook-color);
}
.social-twitter-link {
  @include social-link($brand-twitter-color);
}
.social-linkedin-link {
  @include social-link($brand-linkedin-color);
}
.social-mastodon-link {
  @include social-link($brand-mastodon-color);
}
.social-bluesky-link {
  @include social-link($brand-bluesky-color);
}
.social-whatsapp-link {
  @include social-link($brand-whatsapp-color);
}
.social-youtube-link {
  @include social-link($brand-youtube-color);
}
.social-slideshare-link {
  @include social-link($brand-slideshare-color);
}
.social-blog-link {
  @include social-link($social-blog-color);
}
.social-email-link {
  @include social-link($social-email-color);
}

.social-facebook-colour {
  color: $brand-facebook-color;
}
.social-twitter-colour {
  color: $brand-twitter-color;
}
.social-linkedin-colour {
  color: $brand-linkedin-color;
}
.social-youtube-colour {
  color: $brand-youtube-color;
}
.social-blog-colour {
  color: $social-blog-color;
}
.social-email-colour {
  color: $social-email-color;
}
