/**
 * Bootstrap Label Style Overrides
**/

.label {
  border: 1px solid transparent;
  border-radius: $font-size-base * 1.25;
  font-weight: 300;
}

.label-white {
  @include label-variant(#fff);
  border-color: $kudos-dark-grey;
  color: $kudos-dark-grey;
}

