.editing-help-text {
}

.editing-help-text-toggle {
  float: right;

  i {
    transition: 300ms transform;
  }

  .editing-help-text-visible & {
    i {
      transform: rotateX(180deg);
    }
  }
}

.editing-help-text-content {
  transition: 300ms max-height, 300ms opacity;
  max-height: 0;
  overflow: hidden;
  opacity: 0;

  .editing-help-text-visible & {
    max-height: max-content;
    opacity: 1;
  }

  .top-margin {
    margin-top: 20px;
  }
}
