.section-spacious {
  padding: 2em;
}

.section-spacious-responsive {
  padding: 1em;

  @media (min-width: $screen-sm-min) {
    padding: 2em;
  }
}

.section-container-wrapper {
  // This specifically fixes an "child container is too wide for its parent
  // container with side padding" issue between 768px and 820px.

  // We can set 0 side padding here due to auto side margins on .container, and
  // have the container always centered, without it overflowing at 768px due to
  // the bootstrap grid width of 750px.
  @media (min-width: $screen-sm-min) {
    padding-left: 0;
    padding-right: 0;
  }
}

.section-orange {
  color: #fff;
  background-color: $kudos-light-orange;

  h1 {
    color: #fff;
  }
}

.section-primary {
  color: #fff;
  background-color: $kudos-orange;

  h1 {
    color: #fff;
  }
}

.section-default {
  background-color: #fff;
}

.section-muted {
  background-color: $kudos-light-grey;
}

.section-extra-light-grey {
  background-color: $kudos-extra-light-grey;
}
