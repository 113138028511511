$panel-dashboard-heading-bg: #4784c3;

.panel {
  &.unbordered {
    box-shadow: none;
    border: none;
  }
}

.panel-body-intimate,
.panel-body-textarea {
  padding: 0;
}

.panel-body-textarea {
  textarea {
    border-radius: 0;
    border: none;
  }
}

@mixin panel-dashboard-mixin($heading-bg-color, $border-color: $heading-bg-color, $border-width: 1px, $panel-heading-text-color: $panel-heading-text) {
  @include panel-variant(transparent, $panel-default-text, $heading-bg-color, transparent);
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
  border: none;

  &.bordered {
    box-shadow: none;
    border: $border-width solid $border-color;
  }

  .panel-heading {
    border: none;
    text-align: left;
    padding: 5px 15px;
    color: $panel-heading-text-color;
    font-size: 13pt;

    // Make FontAwesome icons in panel header bigger
    .fa {
      &:before {
        font-size: 13pt;
      }
    }

    kudos-popover-box {
      a {
        color: $panel-heading-text-color;
        &:hover {
          color: $panel-heading-link-hover
        }
      }
      .popover {
        color: $text-color
      }
    }

  }

  .panel-body {
    border-radius: 0 0 $panel-border-radius $panel-border-radius;
    background-color: #fff;
  }

  &.headless {
    .panel-body {
      border-radius: $panel-border-radius;
    }
  }

  .panel-shade,
  .panel-footer {
    background-color: $kudos-extra-light-grey;
  }

  .panel-shade {
    margin-top: $panel-body-padding;
    margin-bottom: -$panel-body-padding;

    padding-top: $panel-body-padding;
    padding-bottom: $panel-body-padding;

    &.vertical-margin-pull-xs {
      @media (min-width: $screen-xs-min) {
        margin-top: -$panel-body-padding;
      }
    }

    &.vertical-margin-pull-sm {
      @media (min-width: $screen-sm-min) {
        margin-top: -$panel-body-padding;
      }
    }

    &.vertical-margin-pull-md {
      @media (min-width: $screen-md-min) {
        margin-top: -$panel-body-padding;
      }
    }
  }
}

.panel-dashboard { @include panel-dashboard-mixin($panel-dashboard-heading-bg) }
.panel-dashboard-orange { @include panel-dashboard-mixin($kudos-orange) }
.panel-dashboard-grey { @include panel-dashboard-mixin($kudos-extra-light-grey, $panel-dashboard-heading-bg, 3px, $text-color) }

.recommended-action {
  &:last-child {
    color: $kudos-orange;
  }
}

.panel-default {
  @include panel-variant($panel-default-border, $panel-default-text, #fff, $panel-default-border);
}

.panel-footer-orange {
  background-color: $kudos-orange;
  color: #fff;
  overflow: auto;

  h2 {
    color: #fff;
    margin-top: 5px;
  }
}

.panel-stacked,
.panel-unbordered-grey {
  @include panel-variant(transparent, $brand-primary, #dce3e9, transparent);

  // override the default 1px white border
  border: none;

  .panel-heading {
    border-radius: 0;
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .panel-body {
    border-radius: 0;
    background-color: $kudos-extra-light-grey;

    &:last-child {
      border-bottom: none;
    }
  }
}

.panel-stacked {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  p {
    margin-top: 0;
    margin-bottom: 0;

    // Allow words to break and wrap, because this is
    // _always_ used in a confined column
    word-break: break-word;
    word-wrap: break-word;
  }

  p {
    // preserve line breaks
    white-space: pre-line;
  }

  // Override Bootstrap default border-radius for square corners
  .nav-pills>li>a {
    border-radius: 0;
  }

  .nav>li>a:hover, .nav>li>a:focus {
    background-color: white;
  }
}

.panel-divider {
  border-top: 1px solid $kudos-light-grey;
}

.panel-card {
  transition: background-color 300ms;
  border: none;
  box-shadow: none;
  margin: 0;

  .panel-heading,
  .panel-body,
  .panel-footer {
    border-radius: 0;
  }

  .panel-heading {
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }

  .panel-body {
    transition: height 300ms;
    height: 330px;
    overflow: hidden;
    h4 {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .card-step {
      height: 300px;
      margin-bottom: 15px;
    }
  }

  .panel-footer {
    border: none;
    background-color: rgb(250, 250, 250);
  }
}

.panel-card-success {
  color: #fff;

  .panel-body {
    text-align: center;
    background-color: $brand-success;
  }

  .success-message-container {
    height: 300px;
  }

  .success-icon {
    font-size: 125px;
  }

  .panel-body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }
}

.panel-hub {
  input {
    margin-bottom: 5px;
  }

  hr {
    border: none;
    height: 1px;
    background: $kudos-light-grey;
    color: $kudos-light-grey;
  }
}

.equal-height-md {
  .panel-hub-title-container {
    margin-bottom: 1em;
  }
}

.panel-hub {
  &.orcid-panel {
    .margin-top-none {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .equal-height-md {
    .panel-hub {
      min-height: 201px;
    }

    .panel-hub-title-container {
      min-height: 60px;
      margin-bottom: 0;
    }

    .panel-hub-body-container {
      min-height: 50px;

      &.form-group,
      &.row {
        margin-bottom: 0;
        min-height: 65px;
      }
    }
  }
}
