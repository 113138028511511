kudos-generate-shareable-link-box, [kudos-generate-shareable-link-box] {
  .share-label-wrapper {
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: $screen-xs-min) {
      justify-content: flex-end;
    }

    .label-input-wrapper {
      align-items: baseline; 
      flex-grow: 1; 
      flex-shrink: 0;

      label {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: right;
      }

      .input-wrapper {
        flex-grow: 1;
        flex-shrink: 0;
        margin: 0 10px 10px;
      }
    }
  }
}
