.how-to-find-me {
	.find-me-submit {
			float: right;
			margin-top: 10px;
			min-width: 80px;       
	}

	.logo {
		margin-left: 2px;
		width: 29px;
	}

	.link-background {
    color: $kudos-orange;
  }
  
  .email-background {
    color: $kudos-light-orange;
  }
}