.page-hub {
  .small {
    font-size: $font-size-small;
    letter-spacing: normal; // Track out for smaller font size
  }
}

.publications-list-filter {
  margin-top: 15px;

  select,
  input {
    display: inline;
    margin: 5px 10px;
    width: auto;
    vertical-align: middle;

    @media (max-width: $screen-xs-min) {
      display: block;
      margin-bottom: 10px;
      width: 92%;
    }
  }
}

.publications-list-filter,
.publications-list-table-wrapper {
  @include box-shadow(1px 1px 5px 1px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
}

.publications-list-table-wrapper {
  margin-bottom: 17px;
}

// Border must be rounded on wrapping elements that have backgrounds or effects.
// (The wrapper has a shadow, so it must be rounded.)
.publications-list-table-wrapper,
.publications-list-table {
  border-radius: 5px 5px 0 0;
}
.publications-list-table th:first-child {
  border-radius: 5px 0 0 0;
}
.publications-list-table th:last-child {
  border-radius: 0 5px 0 0;
}

.view-switcher {
  .view-choice {
    display: inline-block;
    margin: 0;
    margin-left: 5px;
    vertical-align: middle;
    &.selected {
      cursor: default;
    }
  }
  .icon-view-table,
  .icon-view-cards {
    display: block;
    height: 31px;
    width: 31px;
    .item {
      background-color: $kudos-grey;
      border-radius: 2px;
    }
  }
  .selected .item {
    background-color: $kudos-blue;
  }
  .icon-view-table .item {
    height: 9px;
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .icon-view-cards .item {
    height: 14px;
    width: 14px;
    float: left;
    &:nth-child(n + 3) {
      margin-top: 3px;
    }
    &:nth-child(2n) {
      margin-left: 3px;
      clear: right;
    }
  }
}

.publications-list-filter {
  .wrap-for-submit {
    display: inline-block;
  }
  .submit {
    vertical-align: bottom;
    min-width: 82px;
  }
  .search {
    @include box-shadow(inset 0 1px 3px 0 rgba(0, 0, 0, 0.15));
    margin: 0;
  }
  .search-group {
    float: right;
    .input-group:not(:last-child) {
      margin-right: 15px;
    }
  }

  @media (max-width: 425px) {
    .submit {
      display: block;
      width: 100%;
      margin: 0;
    }
  }

  @media (max-width: 425px) {
    .search-group {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 768px) {
    .wrap-for-submit {
      display: inline;
    }
  }
}

// Using !important to override bootstrap/_tables.scss allows us to have simpler
// selectors here.
.publications-list-table {
  font-size: 0.875rem;
  margin-bottom: 0;
  td,
  th {
    vertical-align: middle !important;
  }
  th {
    background-color: $kudos-blue;
    color: #fff;
    font-weight: normal;
    padding: 10px 15px !important;
    // This acts as min-height because table cells will never be shorter than
    // their contents.
    height: 56px;
    border-right: 1px solid $kudos-light-grey !important;
    &.no-border,
    &:last-child {
      border-right: none !important;
    }
  }
  td {
    padding: 20px 15px !important;
    &.featured {
      padding-right: 0 !important;
      text-align: center;
    }
  }
  tr {
    td {
      background-color: #fff;
    }
    &:nth-child(even) td {
      background-color: $kudos-light-blue;
    }
  }
}

// Copied from https://github.com/growkudos/growkudos/blob/ea294a55cc1ca780c83caa2c9349fd2a88a4eada/client/src/styles/ux1/homepage/style.css#L1011-L1016
.popover-btn:hover {
  text-decoration: none;
}
.popover-btn:hover span:hover {
  text-decoration: none;
}
// End copied.

.publications-list-cards {
  .card-flip {
    margin-bottom: 15px;
    // The card must be able to grow in size, but child elements cannot use
    // height: 100% unless the parent has an explicit height set. The solution
    // is to inherit the min-height where we need 100% height.
    // See https://stackoverflow.com/a/43669966/3150057
    min-height: 200px;

    @media (max-width: $screen-xs-min) {
      min-height: 0 !important;
    }

    .panel {
      min-height: inherit;
      margin-bottom: 0;
    }
  }

  .publication-overview-title {
    font-size: 1.16em;
    font-weight: 300;
  }
  .panel.front .publication-overview-title {
    padding-top: 1em;
  }

  .publication-overview-details {
    font-size: 0.9em;
  }

  .publication-overview-authors {
    padding-bottom: 1em;
  }

  .publication-card-img {
    max-width: 100%;
  }

  .percentage-complete {
    width: 100px;
    display: block;
    margin: auto;
  }

  $kpi-widget-size: 95px;
  .kpi-widget .value,
  .altmetric-kpi altmetric-badge .altmetric-embed > a,
  .altmetric-kpi altmetric-badge .altmetric-embed img {
    // Fix unknown almetric badges being slightly larger.
    width: $kpi-widget-size !important;
    height: $kpi-widget-size !important;
    background-size: $kpi-widget-size !important;
  }
  .altmetric-kpi altmetric-badge .altmetric-embed > a {
    display: block !important;
    margin: auto;
  }
}

.save-reset-buttons {
  bottom: 0;
  padding-top: 10px;
  right: 0;

  a {
    margin-left: 30px;
  }
}

.results-count-wrapper {
  input[type="checkbox"] {
    margin: 0 5px 0 2px;
    line-height: normal;
  }

  span {
    margin-left: 3px;
  }
}

.story-list-controls {
  $border-radius: 12px;
  $min-height: 48px;
  $font-size: 16px;

  display: flex;
  align-items: flex-end;

  .story-switch-wrapper {
    display: inline-block;
  }

  .story-switch {
    display: flex;
    flex-wrap: wrap;
    background-color: $kudos-blue;
    color: white;
    padding: 6px;
    gap: 6px;
    border-radius: $border-radius;
    min-height: $min-height;
    // Styles to help us match rg-project-view:
    font-size: $font-size;
    line-height: 1.25;

    > * {
      min-width: 100px;
      max-width: 100%;
      flex: auto;
      padding: 8px 16px;
      text-align: center;
      border-radius: 8px;
    }

    a {
      color: currentColor;
      text-decoration: none;
      transition: background-color 0.3s;

      &:not(.current-page):is(:hover, :focus, :active) {
        background-color: scale-color($color: $kudos-blue, $lightness: 15%);
      }
    }

    .current-page {
      background-color: white;
      color: $kudos-dark-grey;
      box-shadow: 0px 4px 6px 0px $kudos-blue-dark;
      font-weight: 600;
    }
  }

  .action-button .btn-lg {
    // Make the button match the dimensions of the switch so it matches nicely
    min-height: $min-height;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    font-size: $font-size;
  }
}
