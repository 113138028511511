// Bordered version
//
// Add borders all around the table and between all the columns.

.table-borderless {
  border: none;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: none;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 0;
    }
  }
}

.table {
  .small-width {
    width: 130px;
  }

  i.share-channel-icon {
    min-width: 18px;
  }

  &.center-align {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          text-align: center;
        }
      }
    }
  }
}

.table-stretched {
  min-width: 80%;
}

.table-grey-text {
  > tbody > tr > td {
    color: #777;
  }
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.table > tbody > tr > th.table-label,
.table > tbody > tr > td.table-label {
  text-align: right;
  vertical-align: middle;
  font-size: larger;
}

@include table-row-variant('header', $kudos-blue, #fff);
@include table-row-variant('total', $kudos-light-grey);


// Table/Table-cell styles allow divs to behave in a table-like manner
// Use this style for HTML elements that should behave like a table, 
// not for HTML <table> elements.
.tbl {
  display: table;

  &:not(:first-child) {
    padding-top: $padding-base-vertical;
  }

  &:not(:last-child) {
    padding-bottom: $padding-base-vertical;
  }

  .tbl-cell {
    display: table-cell;
    &:not(:first-child) {
      padding-left: $padding-base-horizontal;
    }
  }
}
