table {
  &.list-sort-order {
    td {
      &.btn-col {
        width: 50px;
        text-align: center;
      }
    }
  }
}
