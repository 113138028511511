.hub-tabs {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  margin: 3em 0 3em 0;
  text-align: center;

  .tab {
    flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;

    font-size: 16px;
    padding: 8px;
    text-decoration: none;
    position: relative;

    .pro-image-span {
      color: $kudos-orange;
      position: absolute;
      top: 0;
      padding-left: 4px;

      @media screen and (max-width: $screen-xs-min) {
        display: none;
      }

      img {
        width: 30px;
      }
    }
  }

  .selected {
    border-bottom: 3px solid $kudos-blue;
    color: $kudos-dark-grey;
    font-weight: bold;
  }

  .unselected {
    border-bottom: 1px solid $kudos-grey;
    color: $kudos-grey;
    transition: border-bottom-width 0.15s ease;
  }

  .unselected:hover {
    color: $kudos-dark-grey;
    border-bottom: 3px solid $kudos-blue;
  }
}
