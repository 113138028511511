.featured-notice {
  .icon-col {
    font-size: 20px;
    padding: 0;
  }

  .text-col {
    padding: 0;
  }

  .fa-star {
    margin: 26px 6px 0 0px;
  }
}