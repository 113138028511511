.about-me {
  .about-label {
    padding-top: 0;
  }

  .label-wrapper {
    text-align: left;

    @media (min-width: $screen-xs-max) {
      text-align: right;
    }
  }
  .about-submit {
    float: right;
    margin-top: 10px;
    min-width: 80px;
  }

  .avatar-container {
    padding-left: 40px;
  }

  .avatar-rounded {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    width: 175px;
    height: 175px;

    .avatar-image {
      background-size: 175px 175px;
      width: 175px;
      height: 175px;
    }

    .avatar-legend {
      background: rgba(0, 0, 0, 0.54);
      bottom: 0;
      color: #fff;
      font-size: 11px;
      font-weight: normal;
      left: 0;
      padding: 7px 0;
      position: absolute;
      text-align: center;
      width: 175px;
    }
  }

  .avatar-upload {
    border-radius: 100%;
    cursor: pointer;
    position: relative;
  }

  .avatar-remove {
    margin-left: 10px;
  }

  #file-input {
    display: none;
  }
}
