.submitted {
  .form-group {
    &.invalid {
      label {
        color: $state-danger-text;
      }
    }
  }

  .form-control {
    &:invalid {
      @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
      border-color: $state-danger-text;
      &:focus {
        @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($state-danger-text, 10%));
      }
    }
  }
}
