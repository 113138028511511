.badge-primary {
  background-color: $kudos-blue;
}

.background-kpi {
  padding: 7px 11px;
  border-radius: 15px;
  margin-top: -4px;
  margin-bottom: -4px;
}
