mag-ui-nav-bar {
  --color-bg: white;
  --nav-bar-z-index: 950;
}

@supports (scrollbar-gutter: stable) {
  // Bootstrap accounts for disappearing scrollbars by programmatically applying
  // padding, so we only want to enable stable scrollbars if we're currently
  // showing a mag-ui-web-components modal (otherwise the layout will shift when
  // Bootstrap modals are shown).
  html:has(body.noscroll) {
    scrollbar-gutter: stable;
  }
}

body.noscroll {
  overflow: hidden;
  touch-action: none;
  overscroll-behavior: none;
}

// Old navbar styles

.navbar {
  font-size: 1.166666667em;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  border: none;
  background-color: rgba(255, 255, 255, 1);
}

.navbar.navbar-transparent {
  background-color: rgba(255, 255, 255, 0.9);
}

.navbar-header {
  width: 150px;

  @media (min-width: $screen-sm-min) {
    width: auto;
  }
}

.navbar-header-image {
  max-height: 40px;
  margin-top: 5px;

  @media (min-width: $screen-sm-min) {
    padding-left: 0;
  }
}

.navbar-nav kudos-avatar {
  img,
  svg {
    position: absolute;
    top: 10.5px;
    left: -30px;
    margin-right: 5px;
  }
}

.navbar-default .navbar-nav > li:not(:last-child) > a,
.navbar-default .navbar-nav > li:not(:last-child) > p {
  padding-left: 5px;
  padding-right: 0;

  margin-left: 0;
  margin-right: 0;
  &:after {
    content: "|";
    padding-left: 5px;
  }
}

.navbar-default .navbar-nav > li:last-child > a {
  padding-left: 5px;
  padding-right: 15px;
}

.navbar-fixed-hang {
  width: 100%;

  // TODO - remove this and fix .navbar-fixed-hang component
  .ux3 & .row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .btn {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    flex: 1;
    flex-grow: 1;

    @media (min-width: $screen-sm-min) {
      flex-grow: 0;
    }
  }

  .navbar-fixed-hang-row {
    display: flex;
    justify-content: flex-end;
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding-left: ($grid-gutter-width / 2);
      padding-right: ($grid-gutter-width / 2);
    }
  }
}

.kudos-loader-container {
  // The grid system gives this a 1px min height, which causes a gap between
  // the page contents and the nav bar when the loader isn't shown.
  min-height: 0;
}
