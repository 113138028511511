.site-footer {
  margin-top: 1em;
  margin-bottom: 70px; // Leaves room for floating Zendesk button

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
  }

  .footer-spacious {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .footer-info {
    background: $kudos-dark-grey;
    color: white;

    h3 {
      color: inherit;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .footer-copyright {
    background: white;
    color: $kudos-dark-grey;
    padding-top: 1em;
    padding-bottom: 1em;

    .copyright-items {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .social-icons {
        display: flex;
        flex: auto 0 1;
        font-size: 1.5em;
        margin: 12px 0;

        li {
          margin: 0 0.75em;
        }

        a {
          color: inherit;

          &:hover,
          &:focus,
          &:active {
            color: $kudos-blue;
          }
        }
      }
    }

    .footer-company-address {
      flex: auto 1 1;
      font-size: 0.8em;
    }
  }
}
