// UX2-specific overrides.
//
// Place things here when they conflict with UX3, and you find yourself
// adding a new class in UX3 to undo the UX2 styles.
//
.ux2 {
  p {
    margin: 15px 0;
  }
}
