/**
  Sets an element's width as 50% if it is the specified Bootstrap screen width (or larger)
**/

.halfwidth-xs {
  width: 50%;
}

.halfwidth-sm {
  @media (min-width: $screen-sm-min) {
    width: 50%;
  }
}

.halfwidth-md {
  @media (min-width: $screen-md-min) {
    width: 50%;
  }
}

.halfwidth-lg {
  @media (min-width: $screen-lg-min) {
    width: 50%;
  }
}

/**
  Sets an element's width as 100% if it is the specified Bootstrap screen width (or larger)
**/

.fullwidth-xs {
  width: 100%;
}

.fullwidth-sm {
  @media (min-width: $screen-sm-min) {
    width: 100%;
  }
}

.fullwidth-md {
  @media (min-width: $screen-md-min) {
    width: 100%;
  }
}

.fullwidth-lg {
  @media (min-width: $screen-lg-min) {
    width: 100%;
  }
}

.max-fullwidth {
  max-width: 100%;
}
