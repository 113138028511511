    /*
    Flaticon icon font: Flaticon
    Creation date: 19/07/2018 11:23
    */

@font-face {
  font-family: "Flaticon";
  src: url("/fonts/Flaticon_v1_0_0.eot");
  src: url("/fonts/Flaticon_v1_0_0.eot?#iefix") format("embedded-opentype"),
       url("/fonts/Flaticon_v1_0_0.woff") format("woff"),
       url("/fonts/Flaticon_v1_0_0.ttf") format("truetype"),
       url("/fonts/Flaticon_v1_0_0.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/fonts/Flaticon_v1_0_0.svg#Flaticon") format("svg");
  }
}

.fi:before{
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 32px;
  font-style: normal;
}

.flaticon-concert:before { content: "\f100"; }
.flaticon-team:before { content: "\f101"; }
.flaticon-document:before { content: "\f102"; }
.flaticon-fun:before { content: "\f103"; }
.flaticon-laptop:before { content: "\f104"; }
.flaticon-bullhorn:before { content: "\f105"; }

$font-Flaticon-concert: "\f100";
$font-Flaticon-team: "\f101";
$font-Flaticon-document: "\f102";
$font-Flaticon-fun: "\f103";
$font-Flaticon-laptop: "\f104";
$font-Flaticon-bullhorn: "\f105";
